import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@pepconnect/state';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { CheckoutTransaction } from '@pepconnect/features/checkout/models/checkout-transaction.model';
import { TeamsService } from '@pepconnect/features/teams/services/teams.service';
import { Team } from '@pepconnect/features//teams/models/team.model';
import { ModalsService } from '@pepconnect/services/modals.service';
import { CheckoutStepType } from '@pepconnect/features/checkout/enums/checkout-step-type.enum';
import { UimcSingleSelectItems } from '@pepconnect/shared/presentation/forms/form-models/single-select-item.model';

type TeamPointsForm = Required<{
  team: FormControl<string>;
}>

@Component({
  selector: 'app-individual-purchase',
  templateUrl: './individual-purchase.component.html'
})
export class IndividualPurchaseComponent extends BaseCheckoutComponent implements OnInit {
  teamPointsForm: FormGroup<TeamPointsForm>;
  readonly transaction$ = this.checkoutStore.transaction$;
  transaction: CheckoutTransaction;
  teams: Team[];
  options: UimcSingleSelectItems;
  selectedOption: string;

  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore,
    private teamsService: TeamsService,
    private translateService: TranslateService,
    private modalService: ModalsService) {
    super(store, checkoutStore);
  }

  ngOnInit(): void {
    this.setupForm();
  }

  get f() {
    return this.teamPointsForm.controls;
  }

  setupForm() {
    forkJoin([this.transaction$.pipe(take(1)), this.teamsService.fetchTeams()])
      .subscribe(([tx, teamMembers]) => {
        this.teams = teamMembers
          .filter(t => t.team?.creditCount >= tx.pointsCost && t.isMemberActive)
          .map(t => t.team);

        // used to be able to do partial points purchased.
        // not any more.  If the group doesn't have enough points, boot them to no points or credit card
        if (!this.teams.length) {  // no teams with enough points to purchase this item
          if (tx.canUseCard) {
            this.checkoutStore.setSelectedStep(CheckoutStepType.SavedCards);
          }
          else {
            this.checkoutStore.setSelectedStep(CheckoutStepType.Message);
          }
        }

        // sort by credit count descending
        this.teams.sort((a, b) => b.creditCount - a.creditCount);

        this.options = this.teams.map((t) => ({
          value: t.teamID,
          name: `${t.name} (${t.creditCount} ${this.translateService.instant('TEXT_CREDITS')})`,
          isSelected: (t.teamID === this.teams[0].teamID) // select the first one, sorted about
        }));

        this.selectedOption = this.options.find(o => o.isSelected)?.value;

        this.teamPointsForm = new FormGroup({
          team: new FormControl()
        });

        this.transaction = tx;
      })
  }

  onSelectedItem($event) {
    this.teamPointsForm.controls.team.setValue(this.options.find(o => o.value === $event)?.value);

  }

  onSubmit() {
    if (this.teamPointsForm.invalid) {
      return;
    }

    // open confirmation modal
    this.modalService.definitions.openGroupPointsConfirmModal()
      .afterClosed$
      .subscribe((confirmResponse) => {
        if (confirmResponse?.confirmed) {
          const team = this.teams.find(t => t.teamID === this.f.team.value);
          this.transaction.items[0].team = team;
          // set points applied.  We no longer allow partial points purchases to this is always et to pointsCost
          this.transaction.pointsApplied = this.transaction.pointsCost;
          this.checkoutStore.finalizeTransaction(this.transaction);
        } // if not confirmed, do nothing
      });
  }

  payWithCreditCard() {
    this.checkoutStore.setSelectedStep(CheckoutStepType.SavedCards);
  }
}

