import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@pepconnect/state';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { CheckoutTransaction } from '@pepconnect/features/checkout/models/checkout-transaction.model';
import { CheckoutStepType } from '@pepconnect/features/checkout/enums/checkout-step-type.enum';
import { ModalsService } from '@pepconnect/services/modals.service';

@Component({
  selector: 'app-group-purchase',
  templateUrl: './group-purchase.component.html'
})
export class GroupPurchaseComponent extends BaseCheckoutComponent implements OnInit {
  readonly transaction$ = this.checkoutStore.transaction$;
  transaction: CheckoutTransaction;

  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore,
    private modalService: ModalsService) {
    super(store, checkoutStore);
  }

  ngOnInit(): void {
    this.setupForm();
  }

  setupForm() {
    this.transaction$
      .pipe(take(1))
      .subscribe((tx) => {
        // this used to contain logic for partial points payment.
        // If the group doesn't have enough points, boot them to no points or credit card
        if (!tx.items.length || tx.items[0].team?.creditCount < tx.pointsCost) { // team doesn't have enough points
          if (tx.canUseCard)
            this.checkoutStore.setSelectedStep(CheckoutStepType.SavedCards);
          else
            this.checkoutStore.setSelectedStep(CheckoutStepType.Message);

          return;
        }

        this.transaction = tx;
    })
  }

  onSubmit() {
    // this used to contain logic for partial points payment.  Now it's all or nothing.
    this.modalService.definitions.openGroupPointsConfirmModal()
      .afterClosed$
      .subscribe((confirmResponse) => {
        if (confirmResponse?.confirmed) {
          // set points applied.  We no longer allow partial points purchases to this is always et to pointsCost
          this.transaction.pointsApplied = this.transaction.pointsCost;
          this.checkoutStore.finalizeTransaction(this.transaction);
        } // if not confirmed, do nothing
      });
  }

  payWithCreditCard() {
    this.checkoutStore.setSelectedStep(CheckoutStepType.SavedCards);
  }
}

