<ng-container *ngIf="{
    loading: loading$ | async,
    error: error$ | async
    } as checkoutState">
    <app-form-full-width (onSubmit)="onSubmit()" [formGroup]="billingAddressForm">
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <app-form-group>
                    <app-textfield-group [skip-invalid]="false">
                        <label class="textfield__label" for="billing-address-first-name">{{ 'FIRST_NAME' | translate }}</label>
                        <input type="text" class="textfield__input" id="billing-address-first-name" name="first" formControlName="firstName" autocomplete="given-name">
                        <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true"></app-form-hint>
                        <app-validation-message [control]="f.firstName" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD_FIRST"></app-validation-message>
                    </app-textfield-group>
                </app-form-group>
            </div>
            <div class="col-md-6 col-sm-12">
                <app-form-group>
                    <app-textfield-group [skip-invalid]="false">
                        <label class="textfield__label" for="billing-address-last-name">{{ 'LAST_NAME' | translate }}</label>
                        <input type="text" class="textfield__input" id="billing-address-last-name" name="last" formControlName="lastName" autocomplete="family-name">
                        <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true" [control]="f.lastName"></app-form-hint>
                        <app-validation-message [control]="f.lastName" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD_LAST"></app-validation-message>
                    </app-textfield-group>
                </app-form-group>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-form-group>
                    <app-textfield-group [skip-invalid]="false">
                        <label class="textfield__label" for="billing-address-address-1">{{ 'ADDRESS' | translate }}</label>
                        <input type="text" class="textfield__input" id="billing-address-address-1" name="address1" formControlName="address1">
                        <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true"></app-form-hint>
                        <app-validation-message [control]="f.address1" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD"></app-validation-message>
                    </app-textfield-group>
                </app-form-group>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-form-group>
                    <app-textfield-group [skip-invalid]="false">
                        <label class="textfield__label" for="billing-address-address-2">{{ 'ADDRESS_2' | translate }}</label>
                        <input type="text" class="textfield__input" id="billing-address-address-2" name="address2" formControlName="address2">
                        <app-form-hint hint="LABEL_OPTIONAL" [hideOnSubmitted]="true"></app-form-hint>
                    </app-textfield-group>
                </app-form-group>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-form-group>
                    <app-textfield-group [skip-invalid]="false">
                        <label class="textfield__label" for="billing-address-city">{{ 'CITY' | translate }}</label>
                        <input type="text" class="textfield__input" id="billing-address-city" name="city" formControlName="city">
                        <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true"></app-form-hint>
                        <app-validation-message [control]="f.city" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD"></app-validation-message>
                    </app-textfield-group>
                </app-form-group>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-state-selector [control]="f.state"
                                    [value]="transaction.billingAddress?.state?.id"
                                    [required]="true"
                                    labelKey="REPORT_CITYPROVINCE_LABEL"
                                    (onStateSelect)="onSelectedState($event)"></app-state-selector>
                <app-validation-message [control]="f.state" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD"></app-validation-message>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-form-group>
                    <app-textfield-group [skip-invalid]="false">
                        <label class="textfield__label" for="billing-address-zip">{{ 'ZIP' | translate }}</label>
                        <input type="text" class="textfield__input" id="billing-address-zip" name="zip" formControlName="zip">
                        <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true"></app-form-hint>
                        <app-validation-message [control]="f.zip" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD"></app-validation-message>
                    </app-textfield-group>
                </app-form-group>
            </div>
        </div>
        <app-form-buttons>
            <button id="billing-address-cancel" class="button button--secondary" type="button" *ngIf="!checkoutState.loading" (click)="onCancel()">{{'BUTTON_CANCELPURCHASE' | translate}}</button>
            <button id="billing-address-continue" class="button button--primary" type="submit" *ngIf="!checkoutState.loading">{{ 'BUTTON_CONTINUE'| translate}}</button>
            <app-spinner *ngIf="checkoutState.loading"></app-spinner>
        </app-form-buttons>
    </app-form-full-width>
    <div *ngIf="checkoutState.error" id="error-panel">
        <app-alert indicator="error" [accent-border]="true">
            {{ checkoutState.error | translate }}
        </app-alert>
    </div>
</ng-container>
