import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin} from 'rxjs';
import { distinctUntilKeyChanged, first, take, takeUntil } from 'rxjs/operators';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';
import { CheckoutService } from '@pepconnect/features/checkout/services/checkout.service';
import { CartService } from '@pepconnect/features/checkout/services/cart.service';
import { Router } from '@angular/router';
import { AppState } from '@pepconnect/state';
import { constants } from '@pepconnect/constants/constants';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { provideComponentStore } from '@ngrx/component-store';
import { CheckoutStepType } from "@pepconnect/features/checkout/enums/checkout-step-type.enum";
import { ModalsService } from '@pepconnect/services/modals.service';
import { selectEventCancelCheckout } from '@pepconnect/state/events/events.selector';
import { setCancelCheckout } from '@pepconnect/state/events/events.actions';
import { selectUseCheckoutLayout } from '@pepconnect/state/flags/flags.selector';
import { EnvironmentService } from '@pepconnect/services/environment.service';
import { mockCheckoutItem, mockCheckoutRedirect } from '@pepconnect/features/checkout/mock/mock-items';

/**
 *
 * This components is hit when checkout is initiated
 * It validates items in the cart and adds them to the Checkout Component Store
 * If user is not logged in, it opens the guest checkout step
 * If user is logged in:
 *  api call is made and the Checkout Component Store is initialized with that transaction
 *  saved billing address is fetched and added to the Checkout Component Store
 *  subscriptions (saved cards) are fetched and added to the Checkout Component Store
 *  user is redirected to next step in checkout (based on available prices)
 *
 * This component also watches the Checkout Component Store initializing$ and initError$
 * uses these flags to display loading indicator and handle and initialization errors
 *
 * all other loading indicators and errors during the checkout process are handled by the child components
 *
 * */
@Component({
  selector: 'app-checkout-shell',
  templateUrl: './checkout-shell.component.html',
  providers: [provideComponentStore(CheckoutStore)]
})
export class CheckoutShellComponent extends BaseCheckoutComponent implements OnInit {
  readonly selectedStep$ = this.checkoutStore.selectedStep$;
  readonly authState$ = this.store.select(selectAuthState);
  readonly cancelCheckout$ = this.store.select(selectEventCancelCheckout);
  readonly useCheckoutLayout$ = this.store.select(selectUseCheckoutLayout);
  readonly initError$ = this.checkoutStore.initError$;
  readonly initializing$ = this.checkoutStore.initializing$;
  userLocale: string;
  inlineMode = false;

  constructor(protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore,
    private cartService: CartService,
    private checkoutService: CheckoutService,
    private router: Router,
    private modalService: ModalsService,
    private environmentService: EnvironmentService
  ) {
    super(store, checkoutStore);
  }

  get CheckoutStepType() { return CheckoutStepType; }  // so template can access

  // initialize
  ngOnInit(): void {
    this.setupForInline();
    this.watchForCancel();
    this.setupCheckout();
  }

  setupForInline() {
    if(this.environmentService.isInlineTranslate) {
      this.cartService.initialize(mockCheckoutItem, mockCheckoutRedirect);
      this.inlineMode = true;
      this.authState$.pipe(takeUntil(this.ngUnsubscribe), distinctUntilKeyChanged('isLoggedIn')).subscribe((authState) => {
        this.setupCheckout();
      });
    }
  }

  /**
   * Clear out any transactions in the store, load current transaction from cartservice
   * */
  setupCheckout() {
    // make sure user is initialized
    this.authState$.pipe(first(authState => authState.isInitialized))
      .subscribe((authState) => {
        // save locale in case we have to redirect to home during cancellation
        this.userLocale = authState.user.locale.locale;

        // clear out any current transactions in the store
        this.checkoutStore.clearCheckoutTransaction();

        // take snapshot of redirect url and cart items
        // put it in the store
        this.checkoutStore.setRedirectUrl(this.cartService.getRedirect());

        const items = this.cartService.getItems();
        if (!items?.length) {
          this.checkoutStore.setError('No items in cart');
          return;
        }
        this.checkoutStore.setItems(this.cartService.getItems());

        // if user is not logged in, go to guest registration page
        if (!authState.isLoggedIn) {
          this.checkoutStore.setSelectedStep(this.CheckoutStepType.GuestRegistration);
        }
        else { // user is logged in, initialize transaction
          // call API to initialize checkout, set state in store, redirect
          this.checkoutStore.updateCheckoutUser({
            userId: authState.user.id,
            isGuest: false,
            firstName: authState.user.firstname,
            lastName: authState.user.lastname,
            country: authState.user.country,
            email: authState.user.email,
          });
        }
      });
  }

  /**
   *
   * Watches for changes in cancelCheckout event from main store
   *
   * This can be triggered from child pages or top nav
   *
   * */
  watchForCancel() {
    this.cancelCheckout$.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((cancelCheckoutRequest) => {
        if (cancelCheckoutRequest.cancelCheckout)
          this.onCheckoutCancel(cancelCheckoutRequest.redirectHome);
    })
  }

  /**
   *
   * Opens cancel modal and waits for response
   *
   * */
  onCheckoutCancel(redirectHome: boolean) {
    this.modalService.definitions.openCancelCheckoutModal()
      .afterClosed$
      .pipe(take(1))
      .subscribe((cancelResponse) => {
        if (cancelResponse?.canceled) {
          this.cancelCheckout(redirectHome);
        }
        else {
          this.resetCancelCheckout();
        }
      });
  }

  /**
   *
   * called from cancel checkout confirmation modal if user decided to cancel
   *
   * */
  cancelCheckout(redirectHome: boolean) {
    // get the current transaction and where we are trying to redirect
    forkJoin(
      [this.checkoutStore.transaction$.pipe(take(1)),
        this.checkoutStore.redirectUrl$.pipe(take(1))])
      .subscribe(([transaction, redirectUrl]) => {

        // if no active transaction, redirect and reset cancel flag
        if (transaction === undefined || !transaction.requestId) {
          this.redirectAfterCancel(redirectHome, redirectUrl);
        }
        else { // calls api to cancel transaction and then redirects
          this.checkoutService.cancelTransaction(transaction)
            .pipe(take(1))
            .subscribe({
              next: () => {
                this.redirectAfterCancel(redirectHome, redirectUrl);
              },
              error: () => {
                // redirect even if we get an error
                this.redirectAfterCancel(redirectHome, redirectUrl);
              }
            });
        }
    });
  }

  redirectAfterCancel(redirectHome: boolean, redirectUrl: string) {
    // reset cancel flag
    this.resetCancelCheckout();

    // clear out the current checkout transaction in the store
    this.checkoutStore.clearCheckoutTransaction();

    // redirect out of checkout
    if (redirectHome) {
      this.router.navigate([this.userLocale, constants.HOME]);
    }
    else {
      this.router.navigateByUrl(redirectUrl);
    }
  }

  resetCancelCheckout() {
    this.store.dispatch(setCancelCheckout({ cancelCheckout: false, redirectHome: false }));
  }

}
