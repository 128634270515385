import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@pepconnect/state/index';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';
import { CardStep } from '@pepconnect/features/checkout/models/card-step.model';
import { CardStepType } from '@pepconnect/features/checkout/enums/card-step-type.enum';

@Component({
  selector: 'app-card-checkout',
  templateUrl: './card-checkout.component.html',
  styleUrls: ['./card-checkout.component.scss']
})
export class CardCheckoutComponent extends BaseCheckoutComponent implements OnInit {
  readonly transaction$ = this.checkoutStore.transaction$;
  readonly cardSteps$ = this.checkoutStore.cardSteps$;

  get CardStepType() { return CardStepType; }  // so template can access

  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore) {
    super(store, checkoutStore);
  }

  ngOnInit(): void {
    this.setupCardCheckout();
  }

  setupCardCheckout() {
    this.checkoutStore.clearCardSteps();
  }

  /**
   *
   * Is this step selectable
   * 
   * */
  isStepSelectable(steps: CardStep[], step: CardStepType): boolean {
    return this.isStepStarted(steps, step) && !this.isStepSelected(steps,step)
  }

  onAddressClick(): void {
    this.checkoutStore.setCardStepSelected(CardStepType.Address);
  }

  onPaymentClick(): void {
    this.checkoutStore.setCardStepSelected(CardStepType.Payment);
  }

  onConfirmClick(): void {
    this.checkoutStore.setCardStepSelected(CardStepType.Confirm);
  }
}
