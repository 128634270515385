import { FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { forkJoin, take, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';
import { AppState } from '@pepconnect/state/index';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { CheckoutSubscription } from '@pepconnect/features/checkout/models/checkout-subscription.model';
import { UimcSingleSelectItems } from '@pepconnect/shared/presentation/forms/form-models/single-select-item.model';

type SavedCardsForm = Required<{
  savedCard: FormControl<string>;
}>

@Component({
  selector: 'app-saved-cards',
  templateUrl: './saved-cards.component.html'
})


export class SavedCardsComponent extends BaseCheckoutComponent implements OnInit {
  savedCardsForm: FormGroup<SavedCardsForm>;
  readonly authState$ = this.store.select(selectAuthState);
  readonly subscriptions$ = this.checkoutStore.subscriptions$;
  readonly transaction$ = this.checkoutStore.transaction$;
  readonly MAX_CREDIT_CARD_NAME_DISPLAY = 10;
  subs: CheckoutSubscription[];
  options: UimcSingleSelectItems;
  selectedOption: void;

  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore
  ) {
    super(store, checkoutStore);
  }

  ngOnInit(): void {
    this.authState$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((authState) => {
      this.authState = authState;
      this.setupForm();
    });
  }

  get f() {
    return this.savedCardsForm.controls;
  }

  setupForm() {
    forkJoin([this.subscriptions$.pipe(take(1)), this.transaction$.pipe(take(1))])
      .subscribe(([subs, tx]) => {
        if (!subs?.length) {
          // if no subscriptions, go to card page
          this.goToCard();
        }
        else {
          // get what we need for dropdown
          this.subs = subs;

          // make sure the default transaction sub is in the list, otherwise use the first one
          const defaultSubId =
            (this.subs.find(s => s.id === tx.subscription?.id)) ? tx.subscription.id : subs[0].id;

          this.options = this.subs.map((sub) => ({
            value: sub.id,
            name: `${this.getSmallName(sub.firstName, sub.lastName)} - ${sub.cardType?.name} xxxx-${sub.finalDigits} - Exp. ${sub.expirationMonth}/${sub.expirationYear}`,
            isSelected: (sub.id === defaultSubId)
          })); // select the first one          

          this.savedCardsForm = new FormGroup({
            savedCard: new FormControl()
          });

          this.selectedOption = this.savedCardsForm.controls.savedCard.setValue(this.options.find(o => o.isSelected)?.value);
        }
      })
  }

  onSelectedItem($event) {
    this.savedCardsForm.controls.savedCard.setValue(this.options.find(o => o.value === $event)?.value);
  }

  onSubmit() {
    // go get the transaction, select the appropriate sub, then verify transaction
    this.transaction$
      .pipe(take(1))
      .subscribe((tx) => {
        tx.subscription = this.subs.find(s => s.id === this.f.savedCard.value);

        this.checkoutStore.setTransactionUseExisting(true);

        // this also redirects to next page, sets loading and errors
        this.checkoutStore.verifyTransaction(tx);
      })
  }

  private getSmallName(first: string, last: string) {
    const fullName = `${last}, ${first}`;
    return fullName.length > this.MAX_CREDIT_CARD_NAME_DISPLAY ?
      `${fullName.slice(0, this.MAX_CREDIT_CARD_NAME_DISPLAY)}...` :
      fullName;
  }
}
