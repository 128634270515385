import { take, takeUntil } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { PriceTypeName } from '@pepconnect/enums/price-types.enum';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';
import { CheckoutState } from '@pepconnect/features/checkout/models/checkout-state.model';
import { CheckoutTransaction } from '@pepconnect/features/checkout/models/checkout-transaction.model';
import { CheckoutService } from '@pepconnect/features/checkout/services/checkout.service';
import { defaultFacilityAccountAddressFactory, FacilityAccountAddress } from '@pepconnect/models/facility-account/facility-account-address.model';
import { defaultFacilityAccountPhoneNumberFactory, FacilityAccountPhoneNumber } from '@pepconnect/models/facility-account/facility-account-phone-number.model';
import { defaultFacilityAccountSystemIdentifierFactory, FacilityAccountSystemIdentifier } from '@pepconnect/models/facility-account/facility-account-system-identifier.model';
import { User } from '@pepconnect/models/user/user.model';
import { ModalsService } from '@pepconnect/services/modals.service';
import { selectUser } from '@pepconnect/state/auth/auth.selector';
import { AppState } from '@pepconnect/state/index';
import { defaultFacilityAccountFactory } from '@pepconnect/models/facility-account/facility-account.model';


type FacilityRegistrationForm = Required<{
  facilityName: FormControl<string>,
  accountId: FormControl<string>,
  facilityAddress: FormControl<string>,
  facilityCity: FormControl<string>,
  facilityCityProvince: FormControl<string>,
  facilityZip: FormControl<string>,
  facilityPhone: FormControl<string>,
  facilityFuncLocationNumber: FormControl<string>,
}>

@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html'
})
export class FacilityComponent extends BaseCheckoutComponent implements OnInit {
  readonly transaction$ = this.checkoutStore.transaction$;
  readonly $user = this.store.select((selectUser));
  facilityRegistrationForm: FormGroup<FacilityRegistrationForm>;
  user: User;
  states: CheckoutState[];
  transaction: CheckoutTransaction;

  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore,
    private checkoutService: CheckoutService,
    private modalService: ModalsService,
    private fb: FormBuilder) {
    super(store, checkoutStore);
  }

  ngOnInit(): void {
    this.$user.pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
      this.user = user;
      this.checkoutService.getStates(this.user.country.id).pipe(take(1)).subscribe(states => {
        this.states = states;
      });
    });
    this.setupForm();
  }

  get f() {
    return this.facilityRegistrationForm?.controls;
  }

  initializeFacility(accountId = null) {
    this.transaction.facility = defaultFacilityAccountFactory();
    this.facilityRegistrationForm.reset();
    this.populateFormFields(this.transaction, this.transaction.facility.facilityAccountId ? true : false);
  }

  get requireTCVNum() {
    return this.transaction.selectedPaymentType === PriceTypeName.TCVoucher
  }

  setupForm() {
    this.transaction$.pipe(take(1)).subscribe(tx => {
      this.transaction = tx;

      // If the facility was selected, there is no need to do validations since the data is pulled from existing facility account
      const facilitySelected = tx.facility.facilityAccountId ? true : false;

      this.populateFormFields(tx, facilitySelected);
    });
  }

  private populateFormFields(tx: CheckoutTransaction, facilitySelected: boolean) {
    let accountIdValidators = [];
    if (!this.requireTCVNum && !facilitySelected) {
      accountIdValidators = [Validators.required];
    }
    this.facilityRegistrationForm = this.fb.nonNullable.group({
      facilityName: this.fb.control(tx.facility?.name ?? '', facilitySelected ? [] : [Validators.required]),
      accountId: this.fb.control(tx.facility?.accountId ?? '', accountIdValidators),
      facilityAddress: this.fb.control(tx.facility?.addresses[0]?.street1 ?? '', facilitySelected ? [] : [Validators.required]),
      facilityCity: this.fb.control(tx.facility?.addresses[0]?.city ?? '', facilitySelected ? [] : [Validators.required]),
      facilityCityProvince: this.fb.control(tx.facility?.addresses[0]?.region ?? '', facilitySelected ? [] : [Validators.required]),
      facilityZip: this.fb.control(tx.facility?.addresses[0]?.postcode ?? '', facilitySelected ? [] : [Validators.required]),
      facilityPhone: this.fb.control(tx.facility?.phoneNumbers[0]?.number ?? '', []),
      facilityFuncLocationNumber: this.fb.control(tx.facility?.systemIdentifiers[0]?.systemIdentifier ?? '', []),
    });
  }

  openSidModal() {
    this.modalService.definitions.openSidModal();
  }

  onSubmit() {
    if (!this.facilityRegistrationForm || this.facilityRegistrationForm.invalid) {
      return;
    }

    this.transaction$
      .pipe(take(1))
      .subscribe((tx) => {
        // update transaction with values from form
        const value = this.facilityRegistrationForm.value;
        this.transaction.facility.name = value.facilityName;
        this.transaction.facility.accountId = value.accountId;

        const address: FacilityAccountAddress = defaultFacilityAccountAddressFactory();
        address.street1 = value.facilityAddress;
        address.city = value.facilityCity;
        address.region = value.facilityCityProvince;
        address.postcode = value.facilityZip;
        const phoneNumber: FacilityAccountPhoneNumber = defaultFacilityAccountPhoneNumberFactory();
        phoneNumber.number = value.facilityPhone;
        const sysIdentifier: FacilityAccountSystemIdentifier = defaultFacilityAccountSystemIdentifierFactory();
        sysIdentifier.systemIdentifier = value.facilityFuncLocationNumber

        this.transaction.facility.addresses[0] = address;
        this.transaction.facility.phoneNumbers[0] = phoneNumber;
        this.transaction.facility.systemIdentifiers[0] = sysIdentifier;

        this.checkoutStore.finalizeTransaction(this.transaction);
      });
  }
}
