import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, fromEvent, skip, takeUntil } from 'rxjs';
import { AppState } from '@pepconnect/state/index';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EnvironmentService } from '@pepconnect/services/environment.service';
import { CheckoutTransaction } from '@pepconnect/features/checkout/models/checkout-transaction.model';
import { CardStepType } from '@pepconnect/features/checkout/enums/card-step-type.enum';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { CardStep } from '@pepconnect/features/checkout/models/card-step.model';

/**
 * This component loads the external Sipayce site into an iframe,
 * this monitors the iframe for completion.
 *
 * When iframe is complete, API is called to verify the transaction.
 *
 * If transaction is verified, the user is redirected to the confirmation page.
 * If transaction is canceled, user is sent back to step 1 (billing addres)
 * If the transaction has errors, user is show options to go back to billing address step, re-enter card or cancel checkout
 *
 * If this step is complete and the transaction is verified when the user arrives,
 * a short transaction summary is shown.
 * 
 * */
@Component({
  selector: 'app-payment-sipayce',
  templateUrl: './payment-sipayce.component.html',
  styleUrls: ['./payment-sipayce.component.scss'],
})
export class PaymentSipayceComponent extends BaseCheckoutComponent implements OnInit, AfterViewInit {
  readonly cardSteps$ = this.checkoutStore.cardSteps$;
  readonly transaction$ = this.checkoutStore.transaction$;
  sipayceExternalUrl: SafeResourceUrl;
  transaction: CheckoutTransaction;

  @ViewChild('sipayceIframe', { static: false }) sipayceIframe: ElementRef;

  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore,
    private env: EnvironmentService,
    private sanitizer: DomSanitizer) {
    super(store, checkoutStore);
  }

  ngOnInit(): void {
    this.setupForm();
  }

  setupForm() {
    this.transaction$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged((prev, curr) =>
          prev?.redirectUrl === curr?.redirectUrl))
      .subscribe((tx) => {
        this.transaction = tx; // save transaction for later
        this.sipayceExternalUrl = this.sanitizer.bypassSecurityTrustResourceUrl(tx?.redirectUrl);
      })
  }

  ngAfterViewInit(): void {
    // watch the iframe until the payment processing is complete, canceled or errored
    // in all cases, iframe is redirected back to one of our pages and we call the api to verify
    if (this.sipayceIframe) {
      fromEvent(this.sipayceIframe.nativeElement, 'load')
        // Skip the initial iframe load event, external
        .pipe(
          skip(1),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(() => {
          // check the iframe load event to see if the iframe has changed location to a PEP location
          if (this.sipayceIframe?.nativeElement?.contentDocument?.location?.href.startsWith(this.env.baseUrl)) {
            this.checkoutStore.verifyTransaction(this.transaction);
          }
        });
    }
  }

  onChangeAddress() {
    this.checkoutStore.setError(null);
    this.checkoutStore.setCardStepNotStarted(CardStepType.Payment);
    this.checkoutStore.setCardStepSelected(CardStepType.Address);
  }
  
  onChangePayment() {
    this.checkoutStore.setError(null);
    this.checkoutStore.setCardStepNotStarted(CardStepType.Payment);
    this.checkoutStore.setCardStepSelected(CardStepType.Address);
    this.checkoutStore.createTransaction(this.transaction.billingAddress);
  }

  isPaymentComplete(cardSteps: CardStep[]) {
    return this.isStepCompleted(cardSteps, CardStepType.Payment);
  }

}
