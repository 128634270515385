import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';
import { setUseCheckoutLayout } from '@pepconnect/state/flags/flags.actions';
import { AppState } from '@pepconnect/state/index';
import { PrintPageHelpers } from '@pepconnect/utils/print-page-helpers';

@Component({
  selector: 'app-checkout-order-confirmation',
  templateUrl: './checkout-order-confirmation.component.html'
})
export class CheckoutOrderConfirmationComponent extends BaseCheckoutComponent implements OnInit, OnDestroy {
  readonly transaction$ = this.checkoutStore.transaction$;
  constructor(protected checkoutStore: CheckoutStore, private printHelpers: PrintPageHelpers, protected store: Store<AppState>) {
    super(store, checkoutStore);
  }

  ngOnInit(): void {
    this.store.dispatch(setUseCheckoutLayout({ useCheckoutLayout: false }));
  }

  printPage() {
    this.printHelpers.printPage();
  }

  ngOnDestroy(): void {
    this.checkoutStore.closeCheckoutSuccess(false);
  }

}
