import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';
import { AppState } from '@pepconnect/state/index';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';

@Component({
  selector: 'app-item-cost',
  templateUrl: './item-cost.component.html'
})
export class ItemCostComponent extends BaseCheckoutComponent implements OnInit {
  readonly transaction$ = this.checkoutStore.transaction$;

  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore) {
    super(store, checkoutStore);
  }

  ngOnInit(): void { /* component not yet implemented */ }

}

