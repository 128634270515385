import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';
import { AppState } from '@pepconnect/state/index';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';

@Component({
  selector: 'app-order-total',
  templateUrl: './order-total.component.html',
  styleUrls: ['./order-total.component.scss']
})
export class OrderTotalComponent extends BaseCheckoutComponent {
  readonly transaction$ = this.checkoutStore.transaction$;

  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore) {
    super(store, checkoutStore);
  }
}
