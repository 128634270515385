<ng-container *ngIf="transaction$ | async as transaction">
    <app-collection-item *ngIf="transaction.paymentInfo.cardType">
        <h6 class="margin-bottom-single">{{ 'LABEL_CREDIT_PAYMENT_TYPE_INFO' | translate }}</h6>
            <div>
                {{ 'LABEL_CARD_TYPE' | translate }}&colon;
                <span class="font-weight-bold">&nbsp; {{ transaction.paymentInfo.cardType.name }}</span>
            </div>
            <div>
                {{ 'LABEL_CARD_NUMBER' | translate }}&colon;
                <span class="font-weight-bold"> &nbsp; {{ 'TEXT_ORDER_CC_MASK' | translate }}{{ transaction.paymentInfo.accountNumber }}</span>
            </div>
            <div>
                {{ 'LABEL_EXPIRATION_DATE' | translate }}&colon;
                <span class="font-weight-bold">&nbsp; {{ transaction.paymentInfo.expirationMonth }}&sol;{{ transaction.paymentInfo.expirationYear }}</span>
            </div>
    </app-collection-item>
</ng-container>
