import { createSelector } from '@ngrx/store';
import { selectAppState, AppState } from '..';
import { FlagsState } from './flags.state';

export const selectFlagsState = createSelector(
  selectAppState,
  (state: AppState) => state.flags
);

export const selectUseMinimalLayout = createSelector(
  selectAppState,
  (state: AppState) => state.flags.useMinimalLayout
);

export const selectUseCheckoutLayout = createSelector(
  selectAppState,
  (state: AppState) => state.flags.useCheckoutLayout
);

export const selectSuppressSearchBar = createSelector(
  selectAppState,
  (state: AppState) => state.flags.suppressSearchBar
);
