<ng-container *ngIf="transaction$ | async as transaction">
    <ng-container *ngIf="transaction !== null && transaction.items?.length">
        <div class="row display-flex justify-content-center margin-top-1">
            <app-order-total></app-order-total>
            <ng-container *ngIf="selectedStep$ | async as step">
                <ng-container *ngIf="selectedCardStep$ | async as cardStep">
                    <div class="text-center col-sm-12" *ngIf="step.step === CheckoutStepType.Confirm || (step.step === CheckoutStepType.Card && cardStep.step == CardStepType.Confirm)">
                        <em>{{'TEXT_PURCHASE_WARNING' | translate }}</em>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div class="row display-flex justify-content-center margin-top-1" *ngIf="transaction.pointsApplied > 0">
            {{ transaction.pointsApplied }} {{ 'LABEL_ORDER_POINTS_APPLIED' | translate }}
        </div>
    </ng-container>
</ng-container>
