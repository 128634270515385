<ng-container *ngIf="transaction$ | async as transaction">
    <ng-container *ngIf="transaction !== null">
        <div class="row display-flex justify-content-center subcost" *ngIf="transaction.tax">{{'PROFILE_ORDER_DETAILS_SUBTOTAL' | translate }}: {{ transaction.formattedItemTotal }}</div>
        <div class="row display-flex justify-content-center subcost" *ngIf="transaction.tax">{{'LABEL_TAX' | translate }}: {{ transaction.formattedTax }}*</div>
        <div class="row display-flex justify-content-center cost" *ngIf="transaction.tax">{{'LABEL_TOTAL' | translate }}: {{ transaction.formattedTotal }}</div>
        <div class="row display-flex justify-content-center cost" *ngIf="!transaction.tax">{{'LABEL_TOTAL' | translate }}: {{ transaction.formattedItemTotal }}</div>
        <div class="row display-flex justify-content-center margin-top-1"><em>*{{'CHECKOUT_TAX_MESSAGE' | translate }}</em></div>
        <div class="row display-flex justify-content-center "><em>{{'TEXT_CHECKOUT_PRICES_VARY' | translate }}</em></div>
        <div class="row display-flex justify-content-center "><em>{{'TEXT_PURCHASE_WARNING' | translate }}</em></div>
    </ng-container>
</ng-container>
