import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';
import { CheckoutStepType } from '@pepconnect/features/checkout/enums/checkout-step-type.enum';
import { CheckoutTransaction } from '@pepconnect/features/checkout/models/checkout-transaction.model';
import { PriceType } from '@pepconnect/models/ecomm/price-type.model';
import { defaultFacilityAccountAddressFactory } from '@pepconnect/models/facility-account/facility-account-address.model';
import { defaultFacilityAccountFactory, FacilityAccount } from '@pepconnect/models/facility-account/facility-account.model';
import { User } from '@pepconnect/models/user/user.model';
import { FacilityAccountService } from '@pepconnect/services/facility-account.service';
import { UimcSingleSelectItems } from '@pepconnect/shared/presentation/forms/form-models/single-select-item.model';
import { selectUser } from '@pepconnect/state/auth/auth.selector';
import { AppState } from '@pepconnect/state/index';
import { take, takeUntil } from 'rxjs';

type FacilitySelectForm = Required<{
  tcvNumber: FormControl<string>,
  facilityAccount: FormControl<string>,
  manualAccountId: FormControl<string>,
}>

@Component({
  selector: 'app-facility-select',
  templateUrl: './facility-select.component.html',
  styleUrls: ['facility-select.component.scss']
})
export class FacilitySelectComponent extends BaseCheckoutComponent implements OnInit {
  readonly transaction$ = this.checkoutStore.transaction$;
  readonly $user = this.store.select((selectUser));
  readonly items$ = this.checkoutStore.items$;
  user: User;
  facilitySelectForm: FormGroup<FacilitySelectForm>;
  transaction: CheckoutTransaction;
  facilityPaymentType: PriceType;
  facilitySelectedId: number = -1;
  facilityAccountOptions: UimcSingleSelectItems;
  facilityAccounts: FacilityAccount[];
  selectedOption: string;

  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore,
    private facilityAccountService: FacilityAccountService,
    private fb: FormBuilder,
    private translate: TranslateService) {
    super(store, checkoutStore);
  }

  ngOnInit(): void {
    this.$user.pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
      this.user = user;
      this.facilityAccountService.getFacilityAccounts(user.userGuid).pipe(take(1)).subscribe(faAccs => {
        const activeFacilityAccounts = faAccs.filter(x => x.active);
        // store facilityAccounts for later use
        this.facilityAccounts = activeFacilityAccounts;
        // map to facilityAccountOptions for use in dropdown
        this.facilityAccountOptions = activeFacilityAccounts.map((faAcc) => ({
          value: faAcc.facilityAccountId,
          name: faAcc.name,
          isSelected: false
        }));

        this.facilityAccountOptions.unshift({ name: this.translate.instant('LABEL_SELECT_FACILITY'), value: -1, isSelected: true });
        this.selectedOption = this.facilityAccountOptions.find(f => f.isSelected)?.value;


        this.setupForm();
      });

    });
  }

  get f() {
    return this.facilitySelectForm?.controls;
  }

  get noFacilitySelected() {
    return this.facilitySelectedId === -1;
  }

  onSelectedFacility(event) {
    this.facilitySelectedId = event;
    // find selected facility info and set accountId in the form
    const selFac: FacilityAccount = this.facilityAccounts.find(acc => acc.facilityAccountId === event);
    this.facilitySelectForm.get('manualAccountId').setValue(selFac?.accountId);

    if (!this.facilityPaymentType.requireTCVNum && !this.noFacilitySelected) {
      this.facilitySelectForm.controls.manualAccountId.clearValidators();
    }
    if (!this.facilityPaymentType.requireTCVNum && this.noFacilitySelected) {
      this.facilitySelectForm.controls.manualAccountId.addValidators([Validators.required]);
      this.facilitySelectForm.controls.manualAccountId.markAsTouched();
    }
  }

  setupForm() {
    this.transaction$.pipe(take(1)).subscribe((tx) => {
      this.transaction = tx;
      this.facilityPaymentType = tx.items[0].prices.filter(x => x.type.useFacilityCheckout)[0].type;
      // Send the text of the selected payment through checkout so that it can be included in backend report/email
      this.transaction.selectedPaymentType = this.facilityPaymentType.name;
      this.transaction.isFacilityPurchase = true;

      let tcvNumberValidators = [];
      if (this.facilityPaymentType.requireTCVNum) {
        tcvNumberValidators = [Validators.required];
      }
      let manualAccountIdValidators = [];
      if (!this.facilityPaymentType.requireTCVNum) {
        manualAccountIdValidators = [Validators.required];
      }

      this.facilitySelectForm = this.fb.nonNullable.group({
        tcvNumber: this.fb.control('', tcvNumberValidators),
        facilityAccount: this.fb.control('-1'),
        manualAccountId: this.fb.control('', manualAccountIdValidators)
      });

      if(this.selectedOption) {
        this.facilitySelectForm.controls.facilityAccount.setValue(this.selectedOption);
      }
    });
  }

  onSubmit() {
    if (!this.facilitySelectForm || this.facilitySelectForm.invalid) {
      return;
    }
    // clear any errors before we submit the form
    this.checkoutStore.setError(null);
    const value = this.facilitySelectForm.value;

    this.transaction.facility = defaultFacilityAccountFactory();
    this.transaction.facility.facilityAccountId = value.facilityAccount;
    this.transaction.tCVNumber = value.tcvNumber;

    // Check if user selected a facility in the dropdown
    if (+this.transaction?.facility?.facilityAccountId === -1) {
      // no facility selected, check if there was a manually added account id
      // need to store accountId so we can pass it on if a facility doesn't come back
      const manualAccountId = value.manualAccountId;
      if (manualAccountId) {
        this.facilityAccountService.getByAccountId(manualAccountId, this.user.userGuid)
          .pipe(take(1))
          .subscribe({
            next: data => {
              // if no facility found, initialize a new one and pre-populate with address from user profile
              if (data[0] === undefined) {
                this.prePopulate(manualAccountId);
              } else {
                // otherwise use the facility data retrieved from backend
                this.transaction.facility = data[0];
              }
              this.setTransactionAndProceed();
            },
            error: error => {
              this.checkoutStore.setError(error.Message)
            }
          });
      } else {
        // only TCV number was inserted
        this.prePopulate(manualAccountId);
        this.setTransactionAndProceed();
      }
    }
    else {
      // facility was selected, update the facility data on transaction
      this.transaction.facility = this.facilityAccounts.find(x => x.facilityAccountId === this.transaction.facility.facilityAccountId);
      this.setTransactionAndProceed();
    }
  }

  private getFacilityAddressFromUser() {
    if (this.transaction.facility !== undefined) {
      this.transaction.facility.addresses[0] = defaultFacilityAccountAddressFactory();
      this.transaction.facility.addresses[0].street1 = this.user.address1;
      this.transaction.facility.addresses[0].city = this.user.city;
      this.transaction.facility.addresses[0].region = this.user.state.name;
      this.transaction.facility.addresses[0].postcode = this.user.code.toString();
    }
  }

  private prePopulate(accountId: string) {
    this.transaction.facility.accountId = accountId ?? '';
    this.transaction.facility.facilityAccountId = null;
    this.getFacilityAddressFromUser();
  }

  private setTransactionAndProceed() {
    this.checkoutStore.setTransaction(this.transaction);
    this.checkoutStore.setSelectedStep(CheckoutStepType.Facility);
  }

}
