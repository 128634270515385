import { Component } from '@angular/core';
import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@pepconnect/state/index';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';
import { ModalsService } from '@pepconnect/services/modals.service';
import { CheckoutTransaction } from '@pepconnect/features/checkout/models/checkout-transaction.model';
import { CheckoutStepType } from '@pepconnect/features/checkout/enums/checkout-step-type.enum';

@Component({
  selector: 'app-group-points-checkout',
  templateUrl: './group-points-checkout.component.html'
})
export class GroupPointsCheckoutComponent extends BaseCheckoutComponent {
  readonly transaction$ = this.checkoutStore.transaction$;
  transaction: CheckoutTransaction;
  
  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore,
    private modalService: ModalsService) {
    super(store, checkoutStore);
  }

  setupForm() {
    this.transaction$.pipe(take(1))
      .subscribe((tx) => {
        if (tx.items[0].team?.creditCount < tx.pointsCost) { // team doesn't have enough points to purchase this
          if (tx.canUseCard) {
            this.checkoutStore.setSelectedStep(CheckoutStepType.SavedCards);
          }
          else {
            this.checkoutStore.setSelectedStep(CheckoutStepType.Message);
          }
        }
        this.transaction = tx;
      })
  }
}
