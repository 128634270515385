import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@pepconnect/state/index';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';
import { CheckoutService } from '@pepconnect/features/checkout/services/checkout.service';
import { CardType } from '@pepconnect/features/checkout/models/card-type.model';
import { CheckoutTransaction } from '@pepconnect/features/checkout/models/checkout-transaction.model';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { UimcSingleSelectItems } from '@pepconnect/shared/presentation/forms/form-models/single-select-item.model';

type PaymentForm = Required<{
  cardType: FormControl<string>,
  cardNumber: FormControl<string>,
  expirationMonth: FormControl<string>,
  expirationYear: FormControl<string>,
  cardCode: FormControl<string>,
}>


@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent extends BaseCheckoutComponent implements OnInit {
  readonly transaction$ = this.checkoutStore.transaction$;
  readonly VALIDATOR_CC_MIN_LENGTH = 16;
  readonly VALIDATOR_CC_MAX_LENGTH = 20;  // leave room for 4 spaces
  readonly VALIDATOR_CCV_MAX_LENGTH = 3;
  readonly VALIDATOR_CCV_MIN_LENGTH = 3;
  paymentForm: FormGroup<PaymentForm>;
  transaction: CheckoutTransaction;
  cardOptions: UimcSingleSelectItems;
  monthOptions: UimcSingleSelectItems;
  yearOptions: UimcSingleSelectItems;
  cardTypes: CardType[] =
    [
      { id: '001', name: 'Visa' },
      { id: '002', name: 'MasterCard' },
      { id: '003', name: 'American Express' },
      { id: '004', name: 'Discover' },
    ];

  @ViewChild('ccNumber') ccNumber: ElementRef;
  selectedCard: string;
  selectedMonth: string;
  selectedYear: string;

  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore,
    protected checkoutService: CheckoutService,
    private fb: FormBuilder) {
    super(store, checkoutStore);
  }

  ngOnInit(): void {
    this.setupForm();
  }

  get f() {
    return this.paymentForm.controls;
  }

  setupForm() {
    this.transaction$
      .pipe(take(1))
      .subscribe((tx) => {

        // save the initial transaction for later
        this.transaction = tx;

        this.cardOptions = this.cardTypes.map((card) => ({
          value: card.id,
          name: card.name,
          isSelected: (card.id === tx.paymentInfo?.cardType?.id)
        }));
        this.selectedCard = this.cardOptions.find(card => card.isSelected)?.value;

        const currDate = new Date();

        // make array for each month, set current month to selected
        this.monthOptions = [...Array(12).keys()].map((m) => ({
          value: `0${m + 1}`.slice(-2),
          name: `0${m + 1}`.slice(-2),
          isSelected: (m === currDate.getMonth())
        }));
        this.selectedMonth = this.monthOptions.find(month => month.isSelected)?.value;

        // make array of next 15 years, set current year to selected
        this.yearOptions = [...Array(15).keys()].map((y) => ({
          value: `${y + currDate.getFullYear()}`,
          name: `${y + currDate.getFullYear()}`,
          isSelected: (y === 0)
        }));
        this.selectedYear = this.yearOptions.find(year => year.isSelected)?.value;

        this.paymentForm = new FormGroup({
          cardType: this.fb.control('', [Validators.required]),
          cardNumber: this.fb.control('', { validators: [Validators.required, Validators.minLength(this.VALIDATOR_CC_MIN_LENGTH), Validators.pattern('^[ 0-9]*$')], updateOn: 'blur' }),
          expirationMonth: this.fb.control('', [Validators.required]),
          expirationYear: this.fb.control('', [Validators.required]),
          cardCode: this.fb.control('', { validators: [Validators.required, Validators.minLength(this.VALIDATOR_CCV_MIN_LENGTH), Validators.pattern('^[0-9]*$')], updateOn: 'blur' }),
        });

      })
  }

  onSelectedItem($event) {
    this.paymentForm.controls.cardType.setValue(this.cardOptions.find(c => c.value === $event)?.value);
  }

  onSubmit() {
    // go get the transaction, select the appropriate sub, then verify transaction
    if (!this.paymentForm || this.paymentForm.invalid) {
      return;
    }

    this.transaction$
      .pipe(take(1))
      .subscribe((tx) => {

        const value = this.paymentForm.value;

        tx.paymentInfo = {
          accountNumber: value.cardNumber,
          expirationMonth: value.expirationMonth,
          expirationYear: value.expirationYear,
          cardType: {
            id: value.cardType,
            name: value.cardType
          },
          cvNumber: value.cardCode
        }

        // this also redirects to next page, sets loading and errors
        this.checkoutStore.verifyTransaction(tx);
      })
  }

  /**
   * note: this page is going away soon (with Cybersource).  Did not build this as robustly as possible
   *
   * source: https://stackoverflow.com/questions/50619823/add-space-after-every-4th-digit-in-credit-card-input
   *
   * */
  setCardNumberSpacing() {
    const input = this.ccNumber.nativeElement;
    const { selectionStart } = input;
    const { cardNumber } = this.paymentForm.controls;

    let trimmedCardNum = cardNumber.value.replace(/\s+/g, '');

    if (trimmedCardNum.length > 16) {
      trimmedCardNum = trimmedCardNum.substring(0, 16);
    }

    /* Handle American Express 4-6-5 spacing format */
    const partitions = trimmedCardNum.startsWith('34') || trimmedCardNum.startsWith('37')
      ? [4, 6, 5]
      : [4, 4, 4, 4];

    const numbers = [];
    let position = 0;
    partitions.forEach(partition => {
      const part = trimmedCardNum.substring(position, partition);
      if (part) numbers.push(part);
      position += partition;
    })

    cardNumber.setValue(numbers.join(' '));

    /* Handle caret position if user edits the number later */
    if (selectionStart < cardNumber.value.length - 1) {
      input.setSelectionRange(selectionStart, selectionStart, 'none');
    }
  }
}
