<ng-container *ngIf="{
    initializing: initializing$ | async,
    initError: initError$ | async,
    selectedStep: selectedStep$ | async
    } as checkoutState">
    <page-container id="checkout">
        <app-full-width-wrap>
            <div class="row" *ngIf="checkoutState.initializing">
                <app-spinner class="col-md-12"></app-spinner>
            </div>
            <div *ngIf="checkoutState.initError" id="error-panel">
                <app-alert indicator="error" [accent-border]="true">
                    {{ checkoutState.initError | translate }}
                </app-alert>
                <br/>
                <button id="initialize-cancel" class="button button--primary" type="button" (click)="onCancel()">{{'BUTTON_CANCELPURCHASE' | translate}}</button>
            </div>
            <ng-container *ngIf="!checkoutState.initializing && !checkoutState.initError">
                <app-card-checkout *ngIf="checkoutState.selectedStep?.step === CheckoutStepType.Card"></app-card-checkout>
                <app-facility *ngIf="checkoutState.selectedStep?.step === CheckoutStepType.Facility"></app-facility>
                <app-facility-select *ngIf="checkoutState.selectedStep?.step === CheckoutStepType.FacilitySelect"></app-facility-select>
                <app-guest-registration *ngIf="checkoutState.selectedStep?.step === CheckoutStepType.GuestRegistration"></app-guest-registration>
                <app-checkout-message *ngIf="checkoutState.selectedStep?.step === CheckoutStepType.Message"></app-checkout-message>
                <app-group-points-checkout *ngIf="checkoutState.selectedStep?.step === CheckoutStepType.Points"></app-group-points-checkout>
                <app-saved-cards *ngIf="checkoutState.selectedStep?.step === CheckoutStepType.SavedCards"></app-saved-cards>
                <app-select-payment-type *ngIf="checkoutState.selectedStep?.step === CheckoutStepType.SelectPaymentType"></app-select-payment-type>
                <app-confirm-checkout *ngIf="checkoutState.selectedStep?.step === CheckoutStepType.Confirm"></app-confirm-checkout>
                <app-checkout-order-confirmation *ngIf="checkoutState.selectedStep?.step === CheckoutStepType.OrderConfirmation"></app-checkout-order-confirmation>
            </ng-container>
        </app-full-width-wrap>
    </page-container>
</ng-container>
