import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, first, of, switchMap, tap, withLatestFrom } from "rxjs";
import { ComponentStore } from '@ngrx/component-store';import { tapResponse } from '@ngrx/operators';

import { TranslateService } from "@ngx-translate/core";
import { CardStepType } from "@pepconnect/features/checkout/enums/card-step-type.enum";
import { CheckoutStepType } from "@pepconnect/features/checkout/enums/checkout-step-type.enum";
import { CheckoutTransaction } from "@pepconnect/features/checkout/models/checkout-transaction.model";
import { CheckoutStep } from "@pepconnect/features/checkout/models/checkout-step.model";
import { CardStep } from "@pepconnect/features/checkout/models/card-step.model";
import { CheckoutUser } from "@pepconnect/features/checkout/models/checkout-user.model";
import { OrderItem } from "@pepconnect/models/ecomm/order-item.model";
import { CheckoutService } from "@pepconnect/features/checkout/services/checkout.service";
import { CheckoutSubscription } from "@pepconnect/features/checkout/models/checkout-subscription.model";
import { CheckoutBillingAddress } from "@pepconnect/features/checkout/models/checkout-billing-address.model";
import { CardSummary } from "@pepconnect/features/checkout/models/card-summary.model";
import { ModalsService } from '@pepconnect/services/modals.service';
import { NotificationService } from "@pepconnect/services/notification.service";
import { CartService } from "@pepconnect/features/checkout/services/cart.service";
import { AnalyticsService } from "@pepconnect/services/analytics.service";
import { enumToArray } from "@pepconnect/utils/enum-helpers";

/** Checkout Component Store.
 *  Acts as a mini-store that can be shared as a singleton between component trees
 *  trees to avoid having to pass in a large amount of individual observables as inputs
 *  Usage:
 *  1) To create a new scoped store that can be shared with a component tree - Add a providers[] array to the top-level component metadata with `[provideComponentStore(CheckoutStore)]`.
 *     This will create the singleton.
 *  2) Inject private checkoutStore: CheckoutStore to the constructor (or Inject() function if you live on the wildside) in components that need to access that state.
 *     DO NOT ADD THE PROVIDER to the component metadata for children that should inherit the warm-instance of the store - you only need to inject the store in the constructor
 * */

/**
 * https://ngrx.io/guide/component-store/usage
 * https://www.youtube.com/watch?v=r0Rzt4lQ0T0
 * https://medium.com/ngconf/using-ngrx-component-store-introduction-7787ce250edc
 */

export interface CheckoutState {
  items: OrderItem[], // set when checkout is first launched, initial items in the cart when checkout was intiated
  redirectUrl: string, // set when checkout is first launched, reads from sessionwhere should we go when we are done checkout out
  checkoutUser: CheckoutUser, // set when checkout transaction is first initialized.  Can be guest user
  transaction: CheckoutTransaction, // the transaction state changes a lot, updated from both components and api
  subscriptions: CheckoutSubscription[], // the transaction state changes a lot, updated from both components and api
  cardSummary: CardSummary,
  loading: boolean, // set when api is being called
  error: string | null, // is there an error with the transaction
  initializing: boolean, // set while checkout cart is being verified and transaction is being initialized
  initError: string | null, // set if there is an error when "initializing" the checkout transaction
  steps: CheckoutStep[], // checkout steps and their state
  cardSteps: CardStep[], // card checkout is 3-step wizard, this stores state on that transaction
}

export const defaultCheckoutState: CheckoutState =
{
  items: [],
  redirectUrl: undefined,
  checkoutUser: undefined,
  transaction: undefined,
  subscriptions: [],
  cardSummary: undefined,
  loading: false,
  error: null,
  initializing: false,
  initError: null,
  steps: enumToArray(CheckoutStepType).map(s => ({ step: s, selected: false })),
  cardSteps: enumToArray(CardStepType).map(s => ({ step: s, selected: false, started: false, completed: false }))
}

@Injectable()
export class CheckoutStore extends ComponentStore<CheckoutState> {
  constructor(
    private checkoutService: CheckoutService,
    private cartService: CartService,
    private translate: TranslateService,
    private modalService: ModalsService,
    private notificationService: NotificationService,
    private router: Router,
    private analyticsService: AnalyticsService
  ) {
    super(defaultCheckoutState);
  }

  // *********** Selectors *********** //
  readonly transaction$ = this.select(({ transaction }) => transaction);
  readonly selectedStep$ = this.select(({ steps }) => steps.find(step => step.selected));
  readonly selectedCardStep$ = this.select(({ cardSteps }) => cardSteps.find(cardStep => cardStep.selected));
  readonly cardSteps$ = this.select(({ cardSteps }) => cardSteps);
  readonly redirectUrl$ = this.select(({ redirectUrl }) => redirectUrl);
  readonly loading$ = this.select(({ loading }) => loading);
  readonly error$ = this.select(({ error }) => error);
  readonly initializing$ = this.select(({ initializing }) => initializing);
  readonly initError$ = this.select(({ initError }) => initError);
  readonly subscriptions$ = this.select(({ subscriptions }) => subscriptions);
  readonly checkoutUser$ = this.select(({ checkoutUser }) => checkoutUser);
  readonly cardSummary$ = this.select(({ cardSummary }) => cardSummary);
  readonly items$ = this.select(({ items }) => items);

  // *********** Updaters *********** //
  readonly clearCheckoutTransaction = this.updater((state): CheckoutState => {
    const updatedSteps = Object.assign([], state?.steps);
    if (updatedSteps) {
      updatedSteps.forEach(s => {
        s.selected = false;
      })
    }
    return {
      ...state,
      transaction: undefined,
      steps: updatedSteps
    }
  });


  readonly clearCardSteps = this.updater((state): CheckoutState => {
    const updatedSteps = Object.assign([], state.cardSteps);
    updatedSteps.forEach(s => {
      s.selected = (s.step === CardStepType.Address);
      s.started = (s.step === CardStepType.Address);
      s.completed = false;
    })
    return {
      ...state,
      cardSteps: updatedSteps
    }
  });


  readonly setItems = this.updater((state, items: OrderItem[]): CheckoutState => ({
    ...state,
    items
  }));

  readonly setRedirectUrl = this.updater((state, redirectUrl: string): CheckoutState => ({
    ...state,
    redirectUrl
  }));

  readonly setTransaction = this.updater((state, transaction: CheckoutTransaction): CheckoutState => ({
    ...state,
    transaction
  }));

  readonly setTransactionBillingAddress = this.updater((state, address: CheckoutBillingAddress): CheckoutState => ({
    ...state,
    transaction: {
      ...state.transaction,
      billingAddress: address
    }
  }));

  readonly setCardSummary = this.updater((state, cardSummary: CardSummary): CheckoutState => ({
    ...state,
    cardSummary
  }));

  readonly setError = this.updater((state, error: string | null): CheckoutState => ({
    ...state,
    error
  }));
  7
  readonly setLoading = this.updater((state, loading: boolean): CheckoutState => ({
    ...state,
    loading
  }));

  readonly setInitError = this.updater((state, initError: string | null): CheckoutState => ({
    ...state,
    initError
  }));

  readonly setInitializing = this.updater((state, initializing: boolean): CheckoutState => ({
    ...state,
    initializing
  }));

  readonly setCheckoutUser = this.updater((state, checkoutUser: CheckoutUser): CheckoutState => ({
    ...state,
    checkoutUser
  }));

  readonly setSubscriptions = this.updater((state, subscriptions: CheckoutSubscription[]): CheckoutState => ({
    ...state,
    subscriptions
  }));

  readonly setTransactionUseExisting = this.updater((state, useExisting: boolean): CheckoutState => ({
    ...state,
    transaction: {
      ...state.transaction,
      useExisting
    }
  }));

  /**
   * Used to switch between active checkout steps
   * */
  readonly setSelectedStep = this.updater((state, selectedStep: CheckoutStepType): CheckoutState => {
    const updatedSteps = Object.assign([], state.steps);
    updatedSteps.forEach(s => {
      s.selected = (s.step === selectedStep);
    })
    return {
      ...state,
      steps: updatedSteps
    }
  });

  /**
   * Used to switch selected steps in the card wizard
   * Sets both started and selected flags
   *
   * Only 1 card step can be active at 1 time
   *
   * */
  readonly setCardStepSelected = this.updater((state, selectedCardStep: CardStepType) => {
    const updatedCardSteps = Object.assign([], state.cardSteps);
    updatedCardSteps
      .forEach(s => {
        s.selected = (s.step === selectedCardStep);
      })
    updatedCardSteps
      .filter(s => s.step === selectedCardStep)
      .forEach(s => {
        s.started = true;
      });

    return {
      ...state,
      cardSteps: updatedCardSteps
    }
  });

  /**
  * Used to mark a card step as completed
  * */
  readonly setCardStepCompleted = this.updater((state, completedCardStep: CardStepType) => {
    const updatedCardSteps = Object.assign([], state.cardSteps);
    updatedCardSteps
      .filter(s => s.step === completedCardStep)
      .forEach(s => {
        s.completed = true;
      })
    return {
      ...state,
      cardSteps: updatedCardSteps
    }
  });


  /**
  * Used to mark a card step as not started and not completed
  *
  * Used when user backtracks in checkout
  *
  * */
  readonly setCardStepNotStarted = this.updater((state, notStartedCardStep: CardStepType) => {
    const updatedCardSteps = Object.assign([], state.cardSteps);
    updatedCardSteps
      .filter(s => s.step === notStartedCardStep)
      .forEach(s => {
        s.started = false;
        s.completed = false;
      })
    return {
      ...state,
      cardSteps: updatedCardSteps
    }
  });

  /**
   * Updates the checkoutUser in the store and triggers the updateTransaction effect
   *
   * Called once when checkout is being initalized
   *
   * This is the first effect in a chain of effects that is called when checkout is initialized
   * updateCheckoutUser -> updateTransaction -> (updateBillingAddress auth only) -> (updateSubscription auth only)
   * */
  readonly updateCheckoutUser = this.effect((user$: Observable<CheckoutUser>) => {
    return user$.pipe(
      withLatestFrom(this.items$),
      tap<[CheckoutUser, OrderItem[]]>(([user, items]) => {
        // set flag to indicate that checkout is being initialized
        // used by CheckoutShell to display loading spinner
        this.setInitializing(true);

        // clear any initializatione errors
        // used by CheckoutShell to display error message
        this.setInitError(null);

        this.setCheckoutUser(user);  // save the checkout user

        // hacky set the user id on the first item before sending it to api
        // note: we aren't saving this in the store, only used to initalize checkout
        items[0].userId = user.userId;

        // trigger transaction effect
        this.updateTransaction(items);
      })
    )
  });

  /**
   * initializes transaction with the api and updates it the store
   *
   * Called once when checkout is being initalized
   *
   * This is the second effect in a chain of effects that is called when checkout is initialized
   *
   * This is the second effect in a chain of effects that is called when checkout is initialized
   * updateCheckoutUser -> updateTransaction -> (updateBillingAddress auth only) -> (updateSubscription auth only)
   *
   * */
  readonly updateTransaction = this.effect((cartItems$: Observable<OrderItem[]>) => {
    return cartItems$.pipe(
      withLatestFrom(this.checkoutUser$, this.translate.get('LABEL_POINTS')),
      switchMap(([cartItems, checkoutUser, t]) => {
        // call API to initialize transaction
        // note: this API takes a single item.  In future we may want the full array
        return this.checkoutService.initializeCheckout(cartItems[0]).pipe(
          tapResponse(
            (tx) => {
              // we have a successful transaction, do some tweaking, then save it in store
              tx.items[0].title = tx.items[0].title.replace('@@LABEL_POINTS@@', t);

              // set a default billing address.
              // This may get overwritten for auth user with saved billing address, if country matches
              // for unauth user, this is all we get
              tx.billingAddress = {
                country: checkoutUser.country.code,
                firstName: checkoutUser.firstName,
                lastName: checkoutUser.lastName
              }

              // save transaction in store for later
              this.setTransaction(tx);

              // if not guest user, call effect to update billing address
              // the billing address update will call the effect to get the subscriptions
              if (!checkoutUser.isGuest) {
                this.updateBillingAddress(checkoutUser);
              }
              else {
                this.setInitTransactionRedirect(tx);
              }
            },
            (err: any) => {
              this.onInitError(err);
            }))
      }))
  });

  /**
   * call API to get billing address for authenticated users
   *
   * Called once when checkout is being initalized for auth users only
   *
   * This is the option third effect in a chain of effects that is called when checkout is initialized
   * updateCheckoutUser -> updateTransaction -> (updateBillingAddress auth only) -> (updateSubscription auth only)
   *
   * */
  readonly updateBillingAddress = this.effect((checkoutUser$: Observable<CheckoutUser>) => {
    return checkoutUser$.pipe(
      switchMap((checkoutUser) => {
        // call API to get active saved billing address
        return this.checkoutService.getSavedBillingAddress(checkoutUser.country.id).pipe(
          tapResponse(
            (address) => {
              // if country matches, save address in transaction for later
              if (address && address.country === checkoutUser.country?.code)
                this.setTransactionBillingAddress(address);

              // call effect to get saved cards
              this.updateSubscriptions(checkoutUser);
            },
            (err: any) => {
              this.onInitError(err);
            }));
      }))
  });

  /**
   * call API to get saved subscriptions/cards for checkout user
   *
   * Called once when checkout is being initalized for auth users only
   *
   * This is the option fourth and final effect in a chain of effects that is called when checkout is initialized
   * updateCheckoutUser -> updateTransaction -> (updateBillingAddress auth only) -> (updateSubscription auth only)
   *
   * */
  readonly updateSubscriptions = this.effect((checkoutUser$: Observable<CheckoutUser>) => {
    return checkoutUser$.pipe(
      withLatestFrom(this.transaction$),
      switchMap(([checkoutUser, tx]) => {
        // call API to get active saved cards
        return this.checkoutService.getSubscriptions(checkoutUser.country.id).pipe(
          tapResponse(
            (subscriptions) => {
              if (tx.canUseCard && subscriptions?.length) {  // does this user have any saved cards?
                tx.useExisting = true;
                tx.subscription = subscriptions[0];
              }
              else { // card checkout not enabled or no saved subscriptions
                tx.useExisting = false;
              }

              // save subscriptions & updated transaction in store
              this.setSubscriptions(subscriptions);
              this.setTransaction(tx);
              this.setInitTransactionRedirect(tx);
            },
            (err: any) => {
              this.onInitError(err);
            }));
      }))
  });

  /**
   * called when a billing address is entered in step one of credit card checkout
   *
   * new billing address is sent in as observable
   *
   * transaction.billingAddress is updated to address, then sent into api
   * results from api are loaded back into transaction
   *
   * if successful, got to card checkout step 2 page
   *
   * */
  readonly createTransaction = this.effect((billingAddress$: Observable<CheckoutBillingAddress>) => {
    return billingAddress$.pipe(
      withLatestFrom(this.transaction$),
      switchMap(([billingAddress, transaction]) => {
        this.setLoading(true);
        this.setError(null);

        transaction = {
          ...transaction,
          billingAddress: billingAddress
        };

        // save the billing address with the transaction so we don't lose it if api throws error
        this.setTransactionBillingAddress(billingAddress);

        return this.checkoutService.createTransaction(transaction).pipe(
          tapResponse(
            (tx) => {
              // save transaction in store for later
              this.setTransaction(tx);

              // mark address step complete
              this.setCardStepCompleted(CardStepType.Address);

              // mark future steps not started (in case they clicked back in wizard)
              this.setCardStepNotStarted(CardStepType.Payment);
              this.setCardStepNotStarted(CardStepType.Confirm);

              // go to card checkout step 2 page
              this.setCardStepSelected(CardStepType.Payment);

              this.setLoading(false);
            },
            (err: any) => {
              this.setLoading(false);
              this.onApiError(err);
            }))
      }))
  });


  /**
   * called when a saved card is selected, or when a new card is entered
   *
   * saved card is sent in via CheckoutTransaction as observable
   *
   * transaction.subscription is updated to selected card, and sent into api
   * results from api are loaded back into transaction
   *
   * if successful, got to summary/verification page
   *
   * */
  readonly verifyTransaction = this.effect((transaction$: Observable<CheckoutTransaction>) => {
    return transaction$.pipe(
      withLatestFrom(this.selectedStep$),
      switchMap(([transaction, currentStep]) => {
        // call API to verify transaction that has been previously initialized
        this.setLoading(true);
        this.setError(null);

        return this.checkoutService.verifyTransaction(transaction).pipe(
          tapResponse(
            (tx) => {
              // save transaction in store for later
              this.setTransaction(tx);

              // for external sipayce cancel, we won't have any paymentInfo
              if (!tx.useExisting && !tx.paymentInfo) {
                this.setCardStepNotStarted(CardStepType.Payment);
                this.setCardStepNotStarted(CardStepType.Confirm);
                this.setCardStepSelected(CardStepType.Address);
                this.setLoading(false);
                return;
              }

              // set the chosen card details for use in later steps
              if (tx.useExisting) {
                this.setCardSummary(
                  {
                    cardType: tx.subscription?.cardType?.name,
                    cardNumber: tx.subscription?.finalDigits,
                    expirationMonth: tx.subscription?.expirationMonth,
                    expirationYear: tx.subscription?.expirationYear
                  });
              }
              else {
                this.setCardSummary(
                  {
                    cardType: tx.paymentInfo?.cardType?.name,
                    cardNumber: tx.paymentInfo?.accountNumber?.substring(tx.paymentInfo?.accountNumber?.length - 4),
                    expirationMonth: tx.paymentInfo?.expirationMonth,
                    expirationYear: tx.paymentInfo?.expirationYear
                  });
              }

              // go to next step
              if (currentStep.step === CheckoutStepType.Card) {
                // card wizard
                this.setCardStepCompleted(CardStepType.Payment);
                this.setCardStepNotStarted(CardStepType.Confirm);
                this.setCardStepSelected(CardStepType.Confirm);
              }
              else {
                // saved card confirm
                this.setSelectedStep(CheckoutStepType.Confirm);
              }

              this.setLoading(false);
            },
            (err: any) => {
              this.setLoading(false);
              this.onApiError(err);
            }))
      }))
  });

  /**
   * called to finalize transaction
   *
   * if successful, display success message and redirect back to redirect url
   * if not successful, display error message, stay in checkout
   *
   * */
  readonly finalizeTransaction = this.effect((transaction$: Observable<CheckoutTransaction>) => {
    return transaction$.pipe(
      switchMap((transaction) => {
        this.setError(null);

        // call API to finalize transaction
        this.modalService.definitions.openCheckoutProcessingModal();
        return this.checkoutService.finalizeTransaction(transaction).pipe(
          tapResponse(
            (tx) => {
              this.setTransaction(tx);
              this.trackPlacedOrder(tx);
              this.modalService.closeAllModals();
              this.setSelectedStep(CheckoutStepType.OrderConfirmation);
            },
            (err: any) => {
              this.modalService.closeAllModals();
              this.onApiError(err);
            }))
      }))
  });

  private trackPlacedOrder(transaction: CheckoutTransaction) {
      this.analyticsService.trackPurchaseData({
          action: 'cart.order',
          data: {
              order: {
                  type: transaction.paymentInfo?.accountNumber ? 'credit card' : 'points',
                  status: 'placed'
              },
              products: {
                  id: transaction.items[0]?.productCode ?? transaction.items[0]?.sku ?? '',
                  name: transaction.items[0]?.title,
                  // if the item is Points Only, we need to send 1 as the quantity for correct calculation (i.e. if 100 points - 100 X 1 = 100)
                  quantity: transaction.items[0]?.itemType == 10 ? 1 : transaction.items[0]?.quantity,
                  price: transaction.paymentInfo?.accountNumber ? transaction.items[0]?.totalPrice : 0,
                  currency: transaction.paymentInfo?.accountNumber ? transaction.currencyCode : ''
              }
          }
      });
  }

  /**
   *
   * Translates the successMessage, generates success notification
   * and redirects to page checkout was launched from
   *
   * assumes componentStore/redirectUrl has been set during checkout initialization
   *
   * In case of errors, continue redirect since transaction was already successful
   *
   * */
  readonly closeCheckoutSuccess = this.effect((redirect$: Observable<boolean>) => {
    return redirect$.pipe(
      withLatestFrom(this.redirectUrl$),
      switchMap(([redirect, redirectUrl]) => {
        // call API to get transalation

        this.cartService.clear();
        // note:  clearCheckoutTransaction will reset the redirectUrl.
        // but we're already in tapResponse we still have what we need here
        this.clearCheckoutTransaction();
        if (redirect) {
          this.translate.get('PURCHASE_SUCCESSFUL').pipe(first()).subscribe(t => {
          this.notificationService.successMessage(t);
          this.router.navigateByUrl(redirectUrl);
          });
        }
        return of(null);
      }));
  });


  private onApiError(err: any) {
    this.setLoading(false);
    this.setError(err?.Message ?? err ?? 'error');
  }

  private onInitError(err: any) {
    this.setInitializing(false);
    this.setInitError(err?.Message ?? err ?? 'error');
  }


  /**
   * Update to the next step based on prices in the transaction
   *
   * Assumes transaction.canUsePoints and transaction.canUseCard and transaction.items[0].prices have been set
   *
   * Used once checkout is initialized
   *
   * */
  private setInitTransactionRedirect(tx: CheckoutTransaction) {
    this.setInitializing(false);

    // if prices have facility options, display payment options or send down facility path if no other options exist
    if (tx.items[0]?.prices.some(p => p.type.useFacilityCheckout)) {
      if (tx.canUsePoints || tx.canUseCard) { // both facility & currency/vwp
        this.setSelectedStep(CheckoutStepType.SelectPaymentType);
      }
      else {
        this.setSelectedStep(CheckoutStepType.FacilitySelect); // only facility
      }
    }
    else {
      this.setNextPaymentStep(tx);
    }
  }

  /**
   *
   * Update to the next step based on non-facility prices in the transaction
   *
   * Assumes transaction exists and canUsePoints and useExisting have been set
   *
   * */
  public setNextPaymentStep(tx: CheckoutTransaction) {

    // if no CC allowed and no points allowed, take them to message step
    if ((!tx.canUseCard || !tx.items[0]?.country?.eCommerceEnabled) // no cc
      && !tx.canUsePoints) { // no points available
      this.setSelectedStep(CheckoutStepType.Message);
      return;
    }

    if (tx.canUsePoints) {
      this.setSelectedStep(CheckoutStepType.Points);
    }
    else { // card payment
      if (tx.useExisting) {
        this.setSelectedStep(CheckoutStepType.SavedCards);
      }
      else {
        this.setSelectedStep(CheckoutStepType.Card);
      }
    }
  }
}
