<ng-container *ngIf="{
    loading: loading$ | async,
    error: error$ | async,
    transaction: transaction$ | async
    } as checkoutState">
    <app-form-full-width *ngIf="teamPointsForm" [formGroup]="teamPointsForm" (onSubmit)="onSubmit()">
        <div class="row display-flex flex-column">
            <uimc-filtered-select-v2 id="select-group-points-dropdown" formControlName="team" class="col-sm-12"
                        [labelSearch]="'PLACEHOLDER_TYPE_FILTER' | translate"
                        [label]="'LABEL_TEAM_SELECT' | translate"
                        [filterThreshold]="2"
                        [selectedItem]="selectedOption" 
                        (selectedItemChange)="onSelectedItem($event)">
                            <option *ngFor="let option of options" [value]="option.value">{{option.name}}</option>
            </uimc-filtered-select-v2>
        </div>
        <div *ngIf="checkoutState.transaction?.canUseCard" class="display-flex justify-content-center">
            <span class="uppercase">{{'LABEL_OR' | translate}}</span>
        </div>
        <div *ngIf="checkoutState.transaction?.canUseCard" class="display-flex justify-content-center">
            <button id="use-card" class="button button--secondary" [disabled]="checkoutState.loading" (click)="payWithCreditCard()">{{ 'BUTTON_PAY_WITH_CC'| translate}}</button>
        </div>
        <br/>
        <app-form-buttons class="margin-top-1">
            <button id="individual-purchase-cancel" class="button button--secondary" type="button" *ngIf="!checkoutState.loading" (click)="onCancel()">{{'BUTTON_CANCELPURCHASE' | translate}}</button>
            <button id="individual-purchase-continue" class="button button--primary" type="submit" *ngIf="!checkoutState.loading">{{ 'BUTTON_APPLY_POINTS'| translate}}</button>
            <app-spinner *ngIf="checkoutState.loading"></app-spinner>
        </app-form-buttons>
    </app-form-full-width>
        <div *ngIf="checkoutState.error" id="error-panel">
            <app-alert indicator="error" [accent-border]="true">
                {{ checkoutState.error | translate }}
            </app-alert>
        </div>
</ng-container>
