<ng-container *ngIf="{
    loading: loading$ | async,
    error: error$ | async,
    transaction: transaction$ | async
    } as checkoutState">
    <div class="row margin-top-1 margin-bottom-1 checkout-container">
        <div class="col-md-3 hidden-sm-down"></div>
        <div class="col-lg-4 col-md-6 col-sm-12">
            <app-collection [collapsible]="true" class="flex-margin-t">
                <app-collection-item-collapsible class="hidden-lg-up">
                    <app-collection-collapse-child>
                        <app-collection-item-header>{{ 'ORDER_SUMMARY' | translate}}</app-collection-item-header>
                    </app-collection-collapse-child>
                    <app-collection-collapse-child [collapsed]="false">
                        <app-item-details></app-item-details>
                        <app-item-cost></app-item-cost>
                    </app-collection-collapse-child>
                </app-collection-item-collapsible>
                <app-collection-item *ngIf="checkoutState.transaction?.items[0]?.isPersonal">
                    <app-collection-item-header>{{'LABEL_PAYMENT_INFO' | translate}}</app-collection-item-header>
                </app-collection-item>
                <app-collection-item *ngIf="checkoutState.transaction?.items[0]?.isPersonal">
                    <div class="display-flex justify-content-center">
                        <p class="text-left" [innerHtml]="checkoutState.transaction?.message | translate"></p>
                    </div>
                </app-collection-item>
                <app-collection-item *ngIf="!checkoutState.transaction?.items[0]?.isPersonal">
                    <app-collection-item-header>{{'LINK_PURCHASECREDITS' | translate}}</app-collection-item-header>
                </app-collection-item>
                <app-collection-item *ngIf="!checkoutState.transaction?.items[0]?.isPersonal">
                    <p *ngIf="!checkoutState.transaction?.items[0]?.team?.country?.eCommerceEnabled">{{'TEXT_PURCHASEHOWTO' | translate}}</p>
                    <p *ngIf="checkoutState.transaction?.items[0]?.team?.country?.eCommerceEnabled">{{'LABEL_ORDER_NO_POINTS' | translate}}<p>
                </app-collection-item>
                <app-form-buttons class="margin-top-1">
                    <button id="checkout-message-cancel" class="button button--primary" type="button" *ngIf="!checkoutState.loading" (click)="onCancel()">{{'BUTTON_CANCELPURCHASE' | translate}}</button>
                    <app-spinner *ngIf="checkoutState.loading"></app-spinner>
                </app-form-buttons>
            </app-collection>
        </div>
        <div class="col-lg-3 hidden-md-down">
            <app-collection [collapsible]="true">
                <app-collection-item-collapsible>
                    <app-collection-collapse-child>
                        <app-collection-item-header>{{ 'ORDER_SUMMARY' | translate}}</app-collection-item-header>
                    </app-collection-collapse-child>
                    <app-collection-collapse-child [collapsed]="false">
                        <app-item-details></app-item-details>
                        <app-item-cost></app-item-cost>
                    </app-collection-collapse-child>
                </app-collection-item-collapsible>
            </app-collection>
        </div>
        <div class="col-md-3 col-lg-2 hidden-sm-down"></div>
    </div>
</ng-container>
