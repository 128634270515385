/**
   * Steps for the card entry steps
   */
export enum CardStepType {
  /** Address Form */
  Address,
  /** Confirm Form */
  Confirm,
  /** Payment Form */
  Payment,
}
