import { Inject, Injectable } from "@angular/core";
import { WINDOW } from "@pepconnect/providers/window-provider";

@Injectable({
  providedIn: 'root'
})
export class PrintPageHelpers {
  constructor(@Inject(WINDOW) private window: Window) {}

  /** Print the current page */
  printPage() {
    this.window.print();
  }
}
