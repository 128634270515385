import { Team } from "@pepconnect/features/teams/models/team.model";
import { EventOffering } from "@pepconnect/models/events/event-offering.model";
import { Link } from "@pepconnect/models/general/link.model";
import { Country } from "@pepconnect/models/intl/country.model";
import { Price } from "@pepconnect/models/ecomm/price.model";

export interface CheckoutOrderItem {
  itemType: OrderItemType;
  title: string;
  details: string[];
  credits: number;
  price: number;
  originalPrice: number;
  tax: number;
  courseID: number;
  completionDate: string;
  startDate: string;
  completionID: number;
  objectID: number;
  objectGuid: string;
  planRequest: {};
  certificate: Link;
  teamMemberID: string;
  team: Team;
  points: number;
  originalPoints: number;
  subscriptionLength: string;
  cyberSourceItemID: number;
  subscriptionID: string;
  pEPconnectionsSubscriptionID: string;
  userID: number;
  formattedPrice: string;
  formattedOriginalPrice: string;
  formattedTax: string;
  country: Country;
  links: Link[];
  formatString: string;
  formattedPoints: string;
  formattedTotal: string;
  formattedSubTotal: string;
  pointsTransactionId: string;
  pointUsed: number;
  paymentMethod: {};
  friendlyItemType: string;
  sku: string;
  isPersonal: boolean;
  quantity: number;
  showQuantity: boolean;
  showDisclaimer: boolean;
  disclaimerText: string;
  refundEnabled: boolean;
  offering: EventOffering;
  prices: Price[];
  isExternal: boolean;
  productCode: string;
  totalPoints: number;
  totalPrice: number;
  imagePath: string;
  location: string;
  endDate: string;
  activityCount: number;
}

export enum OrderItemType {
  GeneralItem = 0,
  CourseCertificate = 1,
  CourseSetCertificate = 2,
  EventOfferingCertificate = 5,
  Points = 10,
  OnsiteEventRequest = 13,
  Subscription = 14,
  EventOfferingRegistration = 15,
  Course = 16,
  MediaObject = 17,
  IndividualSubscription = 18
}