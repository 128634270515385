export interface FacilityAccountPhoneNumber {
  facilityAccountPhoneNumberId: string | null;
  facilityAccountId: string;
  usage: string;
  number: string;
}

export function defaultFacilityAccountPhoneNumberFactory(): FacilityAccountPhoneNumber {
  return {
    facilityAccountPhoneNumberId: null,
    facilityAccountId: '',
    usage: '',
    number: '',
  } as FacilityAccountPhoneNumber;
}
