<ng-container *ngIf="cardSummary$ | async as cardSummary">
    <div *ngIf="cardSummary">
        {{ 'LABEL_CARD_TYPE' | translate }}:
        <strong>{{ cardSummary.cardType }}</strong>
        <br />
        {{ 'LABEL_CARD_NUMBER' | translate }}:
        <strong>xxxx-{{ cardSummary.cardNumber }}</strong>
        <br />
        {{ 'LABEL_EXPIRATION_DATE' | translate }}: <strong>{{ cardSummary.expirationMonth}}/{{ cardSummary.expirationYear }}</strong>
    </div>
</ng-container>
