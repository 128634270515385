import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@pepconnect/state/index';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';

type TermsForm = Required<{
  termsAgree: FormControl<boolean>;
}>

@Component({
  selector: 'app-confirm-checkout',
  templateUrl: './confirm-checkout.component.html'
})
export class ConfirmCheckoutComponent extends BaseCheckoutComponent implements OnInit {
  termsForm: FormGroup<TermsForm>;
  readonly transaction$ = this.checkoutStore.transaction$;
  checkoutName: string;

  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore,
    private fb: FormBuilder) {
    super(store, checkoutStore);
  }

  ngOnInit(): void {
    this.setupForm();
  }

  get f() {
    return this.termsForm?.controls;
  }

  setupForm() {
    this.termsForm = this.fb.nonNullable.group({
      termsAgree: this.fb.control(false, [Validators.requiredTrue])
    });
  }

  onTermsAgree($event) {
    this.f.termsAgree.setValue($event);
  }

  onSubmit() {
    this.transaction$
      .pipe(take(1))
      .subscribe((tx) => {
        if (tx.termsAndConditionsLink &&
          (!this.termsForm || this.termsForm.invalid)) {
          return;
        } else {
          tx.termsAndConditionsAccepted = true;
        }
        this.checkoutStore.finalizeTransaction(tx);
      });
  }
}
