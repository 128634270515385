import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { forms } from '@pepconnect/constants/forms';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';
import { CartService } from '@pepconnect/features/checkout/services/cart.service';
import { Country } from '@pepconnect/models//intl/country.model';
import { UnserializedResponseMessage } from '@pepconnect/models/general/response-message.model';
import { Locale } from '@pepconnect/models/localization/locale.model';
import { defaultUserFactory, User } from '@pepconnect/models/user/user.model';
import { ConfigService } from '@pepconnect/services/config-service';
import { EventsService } from '@pepconnect/services/content/events.service';
import { UserService } from '@pepconnect/services/user.service';
import { FormsService } from '@pepconnect/shared/services/forms.service';
import { ScrollService } from '@pepconnect/shared/services/scroll.service';
import { AppState } from '@pepconnect/state';
import { take, takeUntil } from 'rxjs';

type GuestRegistrationForm = Required<{
  firstname: FormControl<string>;
  lastname: FormControl<string>;
  email: FormControl<string>;
  termsAgree: FormControl<boolean>;
  privacyAgree: FormControl<boolean>;
}>

@Component({
  selector: 'app-guest-registration',
  templateUrl: './guest-registration.component.html'
})
export class GuestRegistrationComponent extends BaseCheckoutComponent implements OnInit {
  private readonly configService = inject(ConfigService);

  guestRegistrationForm: FormGroup<GuestRegistrationForm>;
  submitted = false;
  successMessage: string;
  errorMessage: string;
  loading = false;
  formsService: FormsService;
  userService: UserService;
  cartService: CartService;
  eventsService: EventsService;
  scrollService: ScrollService;
  tcmanLink: string;
  showTabsNavigationMessage = false;

  userLocale: Locale;
  userCountry: Country;

  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore,
    private fb: FormBuilder) {
    super(store, checkoutStore);
    this.formsService = inject(FormsService);
    this.userService = inject(UserService);
    this.cartService = inject(CartService);
    this.eventsService = inject(EventsService);
    this.scrollService = inject(ScrollService);
  }

  ngOnInit(): void {
    this.tcmanLink = this.configService.tcManagerSettings.tcmReturnLink;

    this.setupForm();
    this.setUser();
  }

  get f() {
    return this.guestRegistrationForm?.controls;
  }

  setupForm() {
    this.guestRegistrationForm = this.fb.nonNullable.group({
      firstname: this.fb.control('', [Validators.required, Validators.maxLength(forms.VALIDATOR_FIRSTLAST_MAX_LENGTH)]),
      lastname: this.fb.control('', [Validators.required, Validators.maxLength(forms.VALIDATOR_FIRSTLAST_MAX_LENGTH)]),
      email: this.formsService.getEmailControl(this.fb),
      termsAgree: this.fb.control(false, [Validators.requiredTrue]),
      privacyAgree: this.fb.control(false, [Validators.requiredTrue]),
    });
  }


  setUser() {
    this.$user.pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
      // save the user settings for when we are creating new user
      this.userLocale = user.locale;
      this.userCountry = user.country;

      // show a message to use tabs nav is user is ko-kr
      this.showTabsNavigationMessage = this.userLocale?.locale === 'ko-kr';
    });
  }

  onTermsAgree($event) {
    this.f.termsAgree.setValue($event);
  }
  onPrivacyAgree($event) {
    this.f.privacyAgree.setValue($event);
  }

  onSubmit() {
    if (!this.guestRegistrationForm || this.guestRegistrationForm.invalid) {
      return;
    }
    this.loading = true;
    this.resetMessages();
    this.submitted = true;

    // Since we need to send the country/locale as full objects, we can't map from the typed form value to UserDto so we do it manually
    const value = this.guestRegistrationForm.value;
    let payload: User = defaultUserFactory();
    payload = {
      ...payload,
      firstname: value.firstname,
      lastname: value.lastname,
      email: value.email,
      country: this.userCountry,
      locale: this.userLocale
    };

    this.userService.createGuestUser(payload).pipe(take(1)).subscribe({
      next: (res) => {
        // first peek at cart items and see if this is just a waitlist request.  If so, we are done
        const cartItems = this.cartService.getItems();
        if ((cartItems?.length) && cartItems[0].joinWaitList) {
          this.addGuestUserToWaitlist(cartItems[0].eventLink, res.userGuid);
          return;
        }

        this.checkoutStore.updateCheckoutUser(
          {
            userId: res.id,
            isGuest: true,
            firstName: payload.firstname,
            lastName: payload.lastname,
            country: this.userCountry,
            email: payload.email,
          }
        );
      },
      error: (err: UnserializedResponseMessage) => this.onError(err)
    });
  }

  private addGuestUserToWaitlist(link: string, userGuid: string) {
    this.eventsService.addGuestUserToWaitlist(link, userGuid)
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          this.checkoutStore.closeCheckoutSuccess(false);

        },
        error: (err: UnserializedResponseMessage) => this.onError(err)
    });
  }

  resetMessages() {
    this.errorMessage = undefined;
  }

  reset() {
    this.resetMessages();
    this.loading = false;
    this.submitted = false;
    this.guestRegistrationForm.reset();
  }

  onError(err: UnserializedResponseMessage) {
    this.errorMessage = err.Message;
    this.loading = false;
    this.submitted = false;
    this.scrollService.scrollTop();
  }
}
