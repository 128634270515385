export interface FacilityAccountSystemIdentifier {
  facilityAccountSystemIdentifierId: string | null;
  facilityAccountId: string;
  systemIdentifier: string;
}

export function defaultFacilityAccountSystemIdentifierFactory(): FacilityAccountSystemIdentifier {
  return {
    facilityAccountSystemIdentifierId: null,
    facilityAccountId: '',
    systemIdentifier: ''
  } as FacilityAccountSystemIdentifier;
}
