/**
   * Different top-level checkout steps.  Listed here alpha, not in order
   */
export enum CheckoutStepType {
  /** card has child steps:  see CardSteps enum */
  Card,
  /** Facility Checkout Form */
  Facility,
  /** Facility Select Form */
  FacilitySelect,
  /** Guest Registration Form */
  GuestRegistration,
  /** No CC and no points message page */
  Message,
  /** Points Selection Form */
  Points,
  /** Saved Cards Selection Form*/
  SavedCards,
  /** Facility vs Card Selection */
  SelectPaymentType,
  /** Summary/confirm */
  Confirm,
  /** Order Confirmation.  After purchase is successful. */
  OrderConfirmation,
}
