<ng-container *ngIf="{
    loading: loading$ | async,
    error: error$ | async,
    cardSteps: cardSteps$ | async
    } as checkoutState">
    <!--
        External iframe to the Sipayce payment processor site.

        Once user has entered credit card and been redirected back to our
        page, transaction is verified via api, then we show summary of the transaction below
    -->
    <iframe #sipayceIframe
            [hidden]="checkoutState.loading || checkoutState.error || isPaymentComplete(checkoutState.cardSteps)"
            class="sipay-checkout-iframe"
            [class.hidden]="checkoutState.loading || checkoutState.error || isPaymentComplete(checkoutState.cardSteps)"
            id="externalPaymentWindow"
            name="externalPaymentWindow"
            title="External Payment Window"
            [src]="sipayceExternalUrl"
            scrolling="no">
    </iframe>
    <div *ngIf="isPaymentComplete(checkoutState.cardSteps) || checkoutState.error" id="error-panel">
        <app-alert *ngIf="checkoutState.error" indicator="error" [accent-border]="true">
            {{ checkoutState.error | translate }}
        </app-alert>
        <app-card-summary></app-card-summary>
        <app-form-buttons>
            <button id="payment-error-change-address" class="button button--secondary" type="button" *ngIf="!checkoutState.loading" (click)="onChangeAddress()">{{'Change Address' | translate}}</button>
            <button id="payment-error-change-payment" class="button button--secondary" type="button" *ngIf="!checkoutState.loading" (click)="onChangePayment()">{{'Change Card' | translate}}</button>
            <button id="payment-error-cancel" class="button button--secondary" type="button" *ngIf="!checkoutState.loading" (click)="onCancel()">{{'BUTTON_CANCELPURCHASE' | translate}}</button>
        </app-form-buttons>
    </div>
    <app-spinner *ngIf="checkoutState.loading"></app-spinner>
</ng-container>
