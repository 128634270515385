<app-wide-wrap *ngIf="transaction$ | async as transaction">
    <ng-container *ngIf="transaction !== null && transaction.items?.length">
        <div class="row display-flex justify-content-center margin-top-1">
            <div class="col-lg-12 col-xl-6">
                <app-checkout-confirmation-purchase-info></app-checkout-confirmation-purchase-info>
            </div>
            <div class="col-lg-12 col-xl-6">
                <app-collection class="display-block margin-top-1">
                    <app-collection-item>
                        <h4 class="color-black-90">{{ 'ORDER_SUMMARY' | translate }}</h4>
                        <app-collection-item-additional-content class="clickable" (click)="printPage()">
                            <div class="color-black-60">
                                <span class="icon-print"></span> {{ 'CERTIFICATE_PRINT_BUTTON' | translate }}
                            </div>
                        </app-collection-item-additional-content>
                    </app-collection-item>
                    <app-collection-item>
                        <app-item-details></app-item-details>
                    </app-collection-item>
                    <app-checkout-confirmation-total></app-checkout-confirmation-total>
                    <ng-container *ngIf="transaction.paymentInfo.cardType">
                        <app-collection-item>
                            <h4 class="color-black-90">{{ 'LABEL_CUSTOMER_INFO' | translate }}</h4>
                        </app-collection-item>
                        <app-checkout-confirmation-payment-info></app-checkout-confirmation-payment-info>
                        <app-checkout-confirmation-billing-info></app-checkout-confirmation-billing-info>
                    </ng-container>
                    <ng-container *ngIf="transaction.pointsApplied > 0">
                        <app-collection-item>
                            <h4 class="color-black-90">{{ 'LABEL_PURCHASE_INFORMATION' | translate }}</h4>
                        </app-collection-item>
                        <app-checkout-confirmation-points-info></app-checkout-confirmation-points-info>
                    </ng-container>
                </app-collection>
            </div>
        </div>
    </ng-container>
</app-wide-wrap>
