<ng-container *ngIf="{
    loading: loading$ | async,
    error: error$ | async
    } as checkoutState">
    <div class="row" *ngIf="checkoutState.loading">
        <app-spinner class="col-md-12"></app-spinner>
    </div>
    <div class="row col-md-12 margin-bottom-1" *ngIf="checkoutState.error">
        <app-alert indicator="error" [accent-border]="true">
            {{ checkoutState.error | translate }}
        </app-alert>
    </div>
    <div class="row margin-top-1 margin-bottom-1" *ngIf="!checkoutState.error">
        <div class="col-md-3 hidden-sm-down"></div>
        <div class="col-lg-4 col-md-6 col-sm-12">
            <app-collection [collapsible]="true" class="flex-margin-t">
                <app-collection-item-collapsible class="hidden-lg-up">
                    <app-collection-collapse-child>
                        <app-collection-item-header>{{ 'ORDER_SUMMARY' | translate}}</app-collection-item-header>
                    </app-collection-collapse-child>
                    <app-collection-collapse-child [collapsed]="false">
                        <app-item-details></app-item-details>
                        <app-order-summary></app-order-summary>
                    </app-collection-collapse-child>
                </app-collection-item-collapsible>
                <app-collection-item>
                    <app-collection-item-header>{{ 'TEXT_CHOOSE_PAYMENT' | translate}}</app-collection-item-header>
                </app-collection-item>
                <app-collection-item>
                    <app-form-full-width *ngIf="savedCardsForm" [formGroup]="savedCardsForm" (onSubmit)="onSubmit()">
                        <app-form-group>
                            <uimc-filtered-select-v2 id="select-saved-card-dropdown" formControlName="savedCard"
                                        [labelSearch]="'PLACEHOLDER_TYPE_FILTER' | translate"
                                        [label]="'LABEL_SAVED_CARD' | translate"
                                        [filterThreshold]="2"
                                        [selectedItem]="selectedOption" 
                                        (selectedItemChange)="onSelectedItem($event)">
                                            <option *ngFor="let option of options" [value]="option.value">{{option.name}}</option>
                            </uimc-filtered-select-v2>
                        </app-form-group>
                        <div class="display-flex justify-content-center">
                            <span class="uppercase">-{{'LABEL_OR' | translate}}-</span>
                        </div>
                        <div class="display-flex justify-content-center">
                            <button id="select-saved-card-new" class="button button--primary" [disabled]="checkoutState.loading" (click)="goToCard()">{{ 'LABEL_NEW_CARD'| translate}}</button>
                        </div>
                        <div class="display-flex justify-content-left margin-top-1">
                            <label>{{ 'LABEL_CARD_TAX_EXEMPT' | translate }}</label>
                        </div>
                        <app-form-buttons class="margin-top-1">
                            <button id="select-saved-card-cancel" class="button button--secondary" type="button" [disabled]="checkoutState.loading" (click)="onCancel()">{{'BUTTON_CANCELPURCHASE' | translate}}</button>
                            <button id="select-saved-card-continue" class="button button--primary" type="submit" [disabled]="checkoutState.loading">{{ 'BUTTON_CONTINUE'| translate}}</button>
                        </app-form-buttons>
                    </app-form-full-width>
                </app-collection-item>
            </app-collection>
        </div>
        <div class="col-lg-3 hidden-md-down">
            <app-collection [collapsible]="true">
                <app-collection-item-collapsible>
                    <app-collection-collapse-child>
                        <app-collection-item-header>{{ 'ORDER_SUMMARY' | translate}}</app-collection-item-header>
                    </app-collection-collapse-child>
                    <app-collection-collapse-child [collapsed]="false">
                        <app-item-details></app-item-details>
                        <app-order-summary></app-order-summary>
                    </app-collection-collapse-child>
                </app-collection-item-collapsible>
            </app-collection>
        </div>
        <div class="col-md-3 col-lg-2 hidden-sm-down"></div>
    </div>
</ng-container>
