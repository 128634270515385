import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';
import { AppState } from '@pepconnect/state/index';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { CheckoutStepType } from '@pepconnect/features/checkout/enums/checkout-step-type.enum';
import { takeUntil } from 'rxjs';
import { CheckoutOrderItem, OrderItemType } from '@pepconnect/features/checkout/models/checkout-order-item.model';
import { CheckoutTransaction } from '@pepconnect/features/checkout/models/checkout-transaction.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent extends BaseCheckoutComponent implements OnInit {
  readonly transaction$ = this.checkoutStore.transaction$;
  readonly step$ = this.checkoutStore.selectedStep$;
  orderItemType = OrderItemType;
  locale: string;
  showSimpleView = false;

  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore,
    private translate: TranslateService) {
    super(store, checkoutStore);
  }

  ngOnInit(): void {
    this.step$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(step => {
      this.showSimpleView = step.step === CheckoutStepType.OrderConfirmation;
    });
    this.$user.pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
      this.locale = user.locale.locale;
    });
  }
  getImage(item: CheckoutOrderItem) {
    return {
      teamName: item.title,
      avatar: item.imagePath ? item.imagePath : ''
    }
  }

  getItemPriceLabel(transaction: CheckoutTransaction, item: CheckoutOrderItem) {

    let formattedPrice = transaction.paymentInfo.cardType ? item.formattedPrice : this.formatPointsText(item.points);

    if (item.itemType === OrderItemType.Subscription && item.showQuantity) {
      return `${formattedPrice}/${this.translate.instant('LABEL_TEAM_MEMBER')}`;
    }
    if (item.itemType === OrderItemType.Points) {
      return `${formattedPrice}/${this.translate.instant('LABEL_POINT')}`;
    }
    return formattedPrice
  }

  formatPointsText(points: number) {
    const pointsLabel = points === 1 ? 'LABEL_POINT' : 'LABEL_POINTS';
    return `${points} ${this.translate.instant(pointsLabel)}`;
  }
 
  getOfferingDateRange(item: CheckoutOrderItem) {
    if(item.itemType === OrderItemType.EventOfferingRegistration && item.startDate && item.endDate) {
      const startDate = new Date(item.startDate);
      const endDate = new Date(item.endDate);
      return `${startDate.toLocaleDateString(this.locale, {month: 'long'})} ${startDate.toLocaleDateString(this.locale, {day: 'numeric'})} - ${endDate.toLocaleDateString(this.locale, {day: 'numeric'})}, ${endDate.toLocaleDateString(this.locale, {year: 'numeric'})}`;
    }
    return '';
  }
}

