import { Injectable } from '@angular/core';
import { FacilityAccount } from '@pepconnect/models/facility-account/facility-account.model';
import { ApiService } from '@pepconnect/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class FacilityAccountService {

  constructor(private api: ApiService) { }

  /**
   * Called from checkout, facility workflow
   * 
   * userId is Guid
   *
   * */
  getFacilityAccounts(userId: string) {
    return this.api.get<FacilityAccount[]>(`v2/facility/user/${userId}`);
  }

  /**
   * Called from checkout, facility workflow
   * 
   * userId is Guid
   *
   * */
  getByAccountId(accountId: string, userId: string) {
    return this.api.get<FacilityAccount[]>(`v2/facility/${accountId}/${userId}`);
  }
}
