import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@pepconnect/state/index';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';

type ConfirmForm = Required<{
  termsAgree: FormControl<boolean>,
  savePayment: FormControl<boolean>
}>

@Component({
  selector: 'app-card-confirm',
  templateUrl: './card-confirm.component.html'
})
export class CardConfirmComponent extends BaseCheckoutComponent implements OnInit {
  readonly transaction$ = this.checkoutStore.transaction$;
  readonly checkoutUser$ = this.checkoutStore.checkoutUser$; // check if user is guest
  confirmForm: FormGroup<ConfirmForm>;

  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore,
    private fb: FormBuilder) {
    super(store, checkoutStore);
  }

  ngOnInit(): void {
    this.setupForm();
  }

  get f() {
    return this.confirmForm?.controls;
  }

  setupForm() {
    this.confirmForm = this.fb.nonNullable.group({
      termsAgree: this.fb.control(false, [Validators.requiredTrue]),
      savePayment: this.fb.control(false)
    });
  }

  onTermsAgree($event) {
    this.f.termsAgree.setValue($event);
  }

  onSavePayment($event) {
    this.f.savePayment.setValue($event);
  }

  onSubmit() {
    this.transaction$
      .pipe(take(1))
      .subscribe((tx) => {
        if (tx.termsAndConditionsLink &&
          (!this.confirmForm || this.confirmForm.invalid)) {
          return;
        } else {
          tx.termsAndConditionsAccepted = true;
        }

        tx.savePayment = this.f.savePayment.value;

        this.checkoutStore.finalizeTransaction(tx);
      });
  }

}
