import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrderItem } from '@pepconnect/models/ecomm/order-item.model';
import { BaseStateService } from '@pepconnect/shared/base/base-state-service/base-state-service';
import { AppState } from '@pepconnect/state';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { ApiService } from '@pepconnect/services/api.service';

import { CardType } from '@pepconnect/features/checkout/models/card-type.model';
import { CheckoutBillingAddress } from '@pepconnect/features/checkout/models/checkout-billing-address.model';
import { CheckoutSubscription } from '@pepconnect/features/checkout/models/checkout-subscription.model';
import { CheckoutTransaction } from '@pepconnect/features/checkout/models/checkout-transaction.model';
import { UnserializedResponseMessage } from '@pepconnect/models/general/response-message.model';
import { CheckoutState } from '@pepconnect/features/checkout/models/checkout-state.model';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService extends BaseStateService {
  $authState = this.store.select(selectAuthState);

  constructor(
    protected store: Store<AppState>,
    private api: ApiService
  ) {
    super(store);
  }

  initializeCheckout(item: OrderItem) {
    return this.api.post<CheckoutTransaction>('v2/orders/initializeCheckout', item);
  }

  cancelTransaction(transaction: CheckoutTransaction) {
    return this.api.post<UnserializedResponseMessage>('v2/orders/canceltransaction', transaction);
  }

  getSavedBillingAddress(countryId: number) {
    return this.api.get<CheckoutBillingAddress>(`v2/orders/user/${countryId}`);
  }

  getSubscriptions(countryId: number) {
    return this.api.get<CheckoutSubscription[]>(`v2/orders/subscriptions/${countryId}`);
  }

  getCreditCardTypes(countryId: number) {
    return this.api.get<CardType>(`v2/orders/creditcardtypes/${countryId}`);
  }

  getStates(countryId: number) {
    return this.api.get<CheckoutState[]>(`v2/orders/states/${countryId}`);
  }

  createTransaction(transaction: CheckoutTransaction) {
    return this.api.post<CheckoutTransaction>('v2/orders/createtransaction', transaction);
  }

  verifyTransaction(transaction: CheckoutTransaction) {
    return this.api.post<CheckoutTransaction>('v2/orders/verifytransaction', transaction);
  }

  finalizeTransaction(transaction: CheckoutTransaction) {
    return this.api.post<CheckoutTransaction>('orders/cybersource/finalizetransaction', transaction);
  }

}
