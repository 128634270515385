<ng-container *ngIf="{
    loading: loading$ | async,
    error: error$ | async,
    transaction: transaction$ | async,
    checkoutUser: checkoutUser$ | async
    } as checkoutState">
    <div class="row display-flex justify-content-center margin-top-1">
        <div class="col-md-12">
            <app-item-details></app-item-details>
        </div>
    </div>
    <div class="row display-flex justify-content-center margin-top-1">
        <app-order-total></app-order-total>
        <div class="text-center col-md-12"><em>{{'TEXT_PURCHASE_WARNING' | translate }}</em></div>
    </div>
    <div class="row display-flex justify-content-left margin-top-1">
        <div class="col-md-12">
            <app-card-summary></app-card-summary>
            <br />
            <span class="text-left" [innerText]="getCheckoutDisplayName(checkoutState.transaction)">
            </span>
            <br />
            <span class="text-left" [innerHtml]="getCheckoutDisplayAddress(checkoutState.transaction)">
            </span>
            <br />
            <br />
        </div>
    </div>
    <app-form-full-width class="margin-top-1" [formGroup]="confirmForm" (onSubmit)="onSubmit()">
        <app-form-group [hidden]="checkoutState.checkoutUser?.isGuest">
            <uimc-checkbox (checkedChange)="onSavePayment($event)">
                <h6 class="margin-0">{{ 'LABEL_SAVE_PAYMENT_INFO' | translate }}</h6>
            </uimc-checkbox>
        </app-form-group>
        <app-form-group [hidden]="!checkoutState.transaction?.termsAndConditionsLink">
            <uimc-checkbox (checkedChange)="onTermsAgree($event)">
                <h6 class="margin-0">{{ 'LABEL_CHECKOUT_TERMS_AND_CONDITIONS' | translate }}</h6>
            </uimc-checkbox>
            <a href="{{checkoutState.transaction?.termsAndConditionsLink}}" target="_blank" class="icon-link-intern">{{ 'LABEL_CHECKOUT_TERMS_AND_CONDITIONS_LINK'  | translate }}</a>
            <app-validation-message [control]="f.termsAgree" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD" type="none-with-invalid"></app-validation-message>
        </app-form-group>
        <app-form-buttons>
            <button id="card-confirm-cancel" class="button button--secondary" type="button" (click)="onCancel()">{{'BUTTON_CANCELPURCHASE' | translate}}</button>
            <button id="card-confirm-continue" class="button button--primary" type="submit" [disabled]="checkoutState.transaction?.termsAndConditionsLink && !f.termsAgree?.value">{{ 'LABEL_PLACE_ORDER'| translate}}</button>
        </app-form-buttons>
    </app-form-full-width>
</ng-container>
