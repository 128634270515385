<ng-container *ngIf="transaction$ | async as transaction">

    <ng-container *ngIf="transaction !== null && transaction.items?.length && !showSimpleView">
        <ng-container *ngIf="!transaction.items[0].isPersonal && transaction.items[0].team">
            <div class="display-flex justify-content-center">
                <app-user-avatar class="hidden-sm-down" [userAvatar]="TeamHelpers.getTeamAvatar(transaction.items[0].team)" size="small"></app-user-avatar>
            </div>
            <div class="display-flex justify-content-center">
                <h6 class="margin-0">
                    {{transaction.items[0].team.name}}
                </h6>
            </div>
        </ng-container>

        <div class="display-flex justify-content-center margin-top-1">
            <h6 class="text-center margin-0" [innerHtml]="transaction.items[0]?.title + (transaction?.subTitle || '')"></h6>
        </div>

        <div class="display-flex justify-content-center margin-top-1" *ngFor="let detail of transaction.items[0].details">
            <div>
                <span [innerText]="detail"></span>
            </div>
        </div>

        <div class="row tool-tip display-flex justify-content-center margin-top-1" *ngIf="transaction.items[0].showDisclaimer">
            <uimc-tooltip [attr.content]="transaction.items[0].disclaimerText | translate">
                <span class="icon-exclamationmark"></span>{{'LABEL_VIEW_DISCLAIMER' | translate }}
            </uimc-tooltip>
        </div>

        <div class="row display-flex justify-content-center margin-top-1" *ngIf="transaction.items[0].showDisclaimer">
            <strong>{{ 'LABEL_SUBSCRIPTION_ACCESSES' | translate }}&colon; {{ transaction.items[0].quantity }}</strong>
        </div>
    </ng-container>
    <!-- simple view showed on order confirmation screen (last screen after succesful purchase)-->

    <ng-container *ngIf="transaction !== null && transaction.items?.length && showSimpleView">
        <div class="align-items-center" *ngFor="let item of transaction.items">
            <div class="display-flex">
                <div *ngIf="item.imagePath" class="margin-right-qtr">
                    <app-team-avatar [teamAvatar]="getImage(item)" size="medium"
                                     [showOwnerBadge]="false"></app-team-avatar>
                </div>
                <div class="flex-auto">
                    <div>
                        <p class="font-weight-bold margin-bottom-0" [innerHtml]="item.title"></p>
                        <p *ngIf="item.showQuantity && item.itemType === orderItemType.Subscription" class="font-weight-bold margin-bottom-0">{{ 'LABEL_SUBSCRIPTION_ACCESSES' | translate }}&colon; {{ item.quantity }}</p>
                        <p *ngIf="item.itemType === orderItemType.Points" class="font-weight-bold margin-bottom-0">{{'LABEL_POINTS_PURCHASED' | translate }}&colon; {{ item.quantity}}</p>
                        <p *ngIf="item.itemType === orderItemType.CourseCertificate ||
                                    item.itemType === orderItemType.CourseSetCertificate ||
                                    item.itemType === orderItemType.EventOfferingCertificate" class="font-weight-bold margin-bottom-0">{{ item.title }}&colon; {{ item.credits }} {{ 'CEU' | translate }}</p>
                        <p *ngIf="item.itemType === orderItemType.EventOfferingRegistration" class="font-weight-bold margin-bottom-0">{{ getOfferingDateRange(item) }}</p>
                        <p *ngIf="item.activityCount > 0" class="font-weight-bold margin-bottom-0">{{'TEXT_ACTIVITIES' | translate }}&colon; {{ item.activityCount }}</p>
                    </div>
                    <div class="text-align-right">
                        <h6 class="margin-top-0">{{ getItemPriceLabel(transaction, item) }}</h6>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
