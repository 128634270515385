import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@pepconnect/state/index';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';

@Component({
  selector: 'app-checkout-message',
  templateUrl: './checkout-message.component.html'
})
export class CheckoutMessageComponent extends BaseCheckoutComponent {
  readonly transaction$ = this.checkoutStore.transaction$;

  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore) {
    super(store, checkoutStore);
  }
}
