<ng-container *ngIf="{
    loading: loading$ | async,
    error: error$ | async,
    transaction: transaction$ | async
    } as checkoutState">
    <div class="display-flex justify-content-center">
        <app-plural-text [value]="checkoutState?.transaction?.items[0]?.team?.creditCount" single="LABEL_CREDIT_AVAILABLE" plural="LABEL_CREDITS_AVAILABLE"></app-plural-text>
    </div>
    <div class="display-flex justify-content-center margin-top-1">
        <span><strong>{{ checkoutState?.transaction?.pointsCost }} {{ 'POINTS' | translate }}</strong></span>
    </div>
    <div *ngIf="checkoutState.transaction?.canUseCard" class="display-flex justify-content-center">
        <span class="uppercase">{{'LABEL_OR' | translate}}</span>
    </div>
    <div *ngIf="checkoutState.transaction?.canUseCard" class="display-flex justify-content-center">
        <button id="group-purchase-use-card" class="button button--secondary" [disabled]="checkoutState.loading" (click)="payWithCreditCard()">{{ 'BUTTON_PAY_WITH_CC'| translate}}</button>
    </div>
    <br />
    <app-form-buttons class="margin-top-1">
        <button id="group-purchase-cancel" class="button button--secondary" type="button" *ngIf="!checkoutState.loading" (click)="onCancel()">{{'BUTTON_CANCELPURCHASE' | translate}}</button>
        <button id="group-purchase-continue" class="button button--primary" type="button" *ngIf="!checkoutState.loading" (click)="onSubmit()">{{ 'BUTTON_APPLY_POINTS'| translate}}</button>
        <app-spinner *ngIf="checkoutState.loading"></app-spinner>
    </app-form-buttons>
    <div *ngIf="checkoutState.error" id="error-panel">
        <app-alert indicator="error" [accent-border]="true">
            {{ checkoutState.error | translate }}
        </app-alert>
    </div>
</ng-container>
