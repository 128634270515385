<ng-container *ngIf="transaction$ | async as transaction">
    <ng-container *ngIf="transaction !== null">
        <div class="text-center margin-top-1">
            <span class="cost">
                {{'LABEL_COST' | translate }}:
                <span *ngIf="transaction.canUsePoints">
                    <span [innerText]="transaction.pointsCost"></span>&nbsp;
                    <span [ngPlural]="transaction.pointsCost">
                        <ng-template ngPluralCase="=1">
                            {{'LABEL_POINT' | translate}}
                        </ng-template>
                        <ng-template ngPluralCase="other">
                            {{'LABEL_POINTS' | translate}}
                        </ng-template>
                    </span>
                </span>
                <span *ngIf="transaction.canUsePoints && transaction.canUseCard" class="uppercase"> {{ 'LABEL_OR' | translate }} </span>
                <span *ngIf="transaction.canUseCard">{{ transaction.formattedItemTotal }}</span>
            </span>
        </div>
    </ng-container>
</ng-container>
