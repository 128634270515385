<ng-container *ngIf="{
    loading: loading$ | async,
    error: error$ | async
    } as checkoutState">
    <app-form-full-width (onSubmit)="onSubmit()" [formGroup]="paymentForm">
        <div class="row">
            <div class="col-xl-4 col-lg-12">
                <app-form-group>
                    <uimc-filtered-select-v2 id="payment-form-card-type" formControlName="cardType"
                                    [labelSearch]="'PLACEHOLDER_TYPE_FILTER' | translate"
                                    [label]="'LABEL_CARD_TYPE' | translate"
                                    [filterThreshold]="2"
                                    [selectedItem]="selectedCard" 
                                    (selectedItemChange)="onSelectedItem($event)">
                                    <option *ngFor="let option of cardOptions" [value]="option.value">
                                    {{option.name}}</option>
                        <app-form-hint type="selectbox" hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true"></app-form-hint>
                        <app-validation-message [control]="f.cardType" [showOnSubmitted]="true" error="required" message="Card Type Required"></app-validation-message>
                    </uimc-filtered-select-v2>
                </app-form-group>
            </div>
            <div class="col-xl-8 col-lg-12">
                <app-form-group>
                    <app-textfield-group [skip-invalid]="false">
                        <label class="textfield__label" for="payment-form-cc-number">{{ 'LABEL_CARD_NUMBER' | translate }}</label>
                        <input #ccNumber
                               type="text"
                               class="textfield__input"
                               id="payment-form-cc-number"
                               name="cc-number"
                               [maxlength]="VALIDATOR_CC_MAX_LENGTH"
                               formControlName="cardNumber">
                        <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true"></app-form-hint>
                        <app-validation-message [control]="f.cardNumber" [showOnSubmitted]="true" error="required" message="Card Number Required"></app-validation-message>
                        <app-validation-message [control]="f.cardNumber" [showOnSubmitted]="true" error="pattern" message="Invalid Card Number"></app-validation-message>
                        <app-validation-message [control]="f.cardNumber" [showOnSubmitted]="true" error="minLength" message="Invalid Card Length"></app-validation-message>
                    </app-textfield-group>
                </app-form-group>
            </div>
            <div class="col-xl-4 col-lg-6">
                <app-form-group>
                    <uimc-filtered-select-v2 id="payment-form-expiration-month" formControlName="expirationMonth"
                                    [labelSearch]="'PLACEHOLDER_TYPE_FILTER' | translate"
                                    label="Exp. Month"
                                    [filterThreshold]="2"
                                    [selectedItem]="selectedMonth" 
                                    (selectedItemChange)="onSelectedItem($event)">
                                    <option *ngFor="let option of monthOptions" [value]="option.value">
                                    {{option.name}}</option>
                        <app-form-hint type="selectbox" class="margin-bottom-0" hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true"></app-form-hint>
                        <app-validation-message [control]="f.expirationMonth" [showOnSubmitted]="true" error="required" message="Exp. Month Required"></app-validation-message>
                    </uimc-filtered-select-v2>
                </app-form-group>
            </div>
            <div class="col-xl-4 col-lg-6">
                <app-form-group>
                    <uimc-filtered-select-v2 id="payment-form-expiration-year" formControlName="expirationYear"
                                    [labelSearch]="'PLACEHOLDER_TYPE_FILTER' | translate"
                                    label="Exp. Year"
                                    [filterThreshold]="2"
                                    [selectedItem]="selectedYear" 
                                    (selectedItemChange)="onSelectedItem($event)">
                                    <option *ngFor="let option of yearOptions" [value]="option.value">
                                    {{option.name}}</option>
                        <app-form-hint type="selectbox" class="margin-bottom-0" hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true"></app-form-hint>
                        <app-validation-message [control]="f.expirationYear" [showOnSubmitted]="true" error="required" message="Exp. Year Required"></app-validation-message>
                    </uimc-filtered-select-v2>
                </app-form-group>
            </div>
            <div class="col-xl-4 col-lg-6">
                <app-form-group>
                    <app-textfield-group [skip-invalid]="false">
                        <label class="textfield__label" for="payment-form-ccv">{{ 'LABEL_CARD_CODE' | translate }}</label>
                        <input type="text"
                               class="textfield__input"
                               id="payment-form-ccv"
                               name="card-code"
                               formControlName="cardCode"
                               [maxlength]="VALIDATOR_CCV_MAX_LENGTH">
                        <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true"></app-form-hint>
                        <app-validation-message [control]="f.cardCode" [showOnSubmitted]="true" error="required" message="CCV required"></app-validation-message>
                        <app-validation-message [control]="f.cardCode" [showOnSubmitted]="true" error="pattern" message="Invalid CCV"></app-validation-message>
                        <app-validation-message [control]="f.cardCode" [showOnSubmitted]="true" error="minLength" message="Invalid CCV"></app-validation-message>
                    </app-textfield-group>
                </app-form-group>
            </div>

            <div class="col-md-12">
                {{ 'LABEL_CARD_TAX_EXEMPT' | translate }}
            </div>
        </div>
        <app-form-buttons>
            <button id="payment-form-cancel" class="button button--secondary" type="button" *ngIf="!checkoutState.loading" (click)="onCancel()">{{'BUTTON_CANCELPURCHASE' | translate}}</button>
            <button id="payment-form-continue" class="button button--primary" type="submit" *ngIf="!checkoutState.loading">{{ 'BUTTON_SAVE_CONTINUE'| translate}}</button>
            <app-spinner *ngIf="checkoutState.loading"></app-spinner>
        </app-form-buttons>
    </app-form-full-width>
    <div *ngIf="checkoutState.error" id="error-panel">
        <app-alert indicator="error" [accent-border]="true">
            {{ checkoutState.error | translate }}
        </app-alert>
    </div>
</ng-container>
