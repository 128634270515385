import { constants } from "@pepconnect/constants";
import { Country, defaultUserCountryFactory } from "@pepconnect/models/intl/country.model";

export interface FacilityAccountAddress {
  facilityAccountAddressId: string | null;
  facilityAccountId: string;
  label: string;
  usage: string;
  street1: string;
  street2: string;
  street3: string;
  city: string;
  town: string;
  region: string;
  postcode: string;
  countryId: number;
  countryName: string;
  countryCode: string;
  countryCode2: string;
  country: Country;
}

export function defaultFacilityAccountAddressFactory(): FacilityAccountAddress {
  return {
    facilityAccountAddressId: null,
    facilityAccountId: '',
    label: '',
    usage: '',
    street1: '',
    street2: '',
    street3: '',
    city: '',
    town: '',
    region: '',
    postcode: '',
    countryId: 0,
    countryName: '',
    countryCode: '',
    countryCode2: '',
    country: !!localStorage.getItem(constants.LS_SITE_COUNTRY)
      ? JSON.parse(localStorage.getItem(constants.LS_SITE_COUNTRY))
      : defaultUserCountryFactory()
  } as FacilityAccountAddress;
}
