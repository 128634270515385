<ng-container *ngIf="{
    cardSteps: cardSteps$ | async,
    transaction: transaction$ | async
    } as checkoutState">
    <!-- Child components handle error$ and loading$ -->
    <div class="row margin-top-1 margin-bottom-1 checkout-container">
        <div class="col-md-3 hidden-sm-down"></div>
        <div class="col-lg-4 col-md-6 col-sm-12">
            <app-collection [collapsible]="true" class="flex-margin-t">
                <app-collection-item-collapsible class="hidden-lg-up">
                    <app-collection-collapse-child>
                        <app-collection-item-header>{{ 'ORDER_SUMMARY' | translate}}</app-collection-item-header>
                    </app-collection-collapse-child>
                    <app-collection-collapse-child [collapsed]="false">
                        <app-item-details></app-item-details>
                        <app-order-summary></app-order-summary>
                    </app-collection-collapse-child>
                </app-collection-item-collapsible>
                <app-collection-item (click)="isStepSelectable(checkoutState.cardSteps, CardStepType.Address) && onAddressClick()"
                                     [class.selectable]="isStepSelectable(checkoutState.cardSteps,CardStepType.Address)">
                    <app-collection-item-header>1. {{'LABEL_BILLING_ADDRESS' | translate}}</app-collection-item-header>
                </app-collection-item>
                <app-collection-item [hidden]="!isStepSelected(checkoutState.cardSteps, CardStepType.Address)">
                    <app-billing-address></app-billing-address>
                </app-collection-item>
                <app-collection-item (click)="isStepSelectable(checkoutState.cardSteps, CardStepType.Payment) && onPaymentClick()"
                                     [class.disabled]="!isStepStarted(checkoutState.cardSteps, CardStepType.Payment)"
                                     [class.selectable]="isStepSelectable(checkoutState.cardSteps,CardStepType.Payment)">
                    <app-collection-item-header>2. {{'LABEL_PAYMENT_INFO' | translate}}</app-collection-item-header>
                </app-collection-item>
                <app-collection-item [hidden]="!isStepSelected(checkoutState.cardSteps, CardStepType.Payment)">
                    <app-payment-sipayce *ngIf="checkoutState.transaction?.redirectUrl"></app-payment-sipayce>
                    <app-payment-form *ngIf="!checkoutState.transaction?.redirectUrl"></app-payment-form>
                </app-collection-item>
                <app-collection-item (click)="isStepSelectable(checkoutState.cardSteps, CardStepType.Confirm) && onConfirmClick()"
                                     [class.disabled]="!isStepStarted(checkoutState.cardSteps,CardStepType.Confirm)"
                                     [class.selectable]="isStepSelectable(checkoutState.cardSteps,CardStepType.Confirm)">
                    <app-collection-item-header>3. {{'ORDER_REVIEW' | translate}}</app-collection-item-header>
                </app-collection-item>
                <app-collection-item [hidden]="!isStepSelected(checkoutState.cardSteps, CardStepType.Confirm)">
                    <app-card-confirm></app-card-confirm>
                </app-collection-item>
            </app-collection>
        </div>
        <div class="col-lg-3 hidden-md-down">
            <app-collection [collapsible]="true">
                <app-collection-item-collapsible>
                    <app-collection-collapse-child>
                        <app-collection-item-header>{{ 'ORDER_SUMMARY' | translate}}</app-collection-item-header>
                    </app-collection-collapse-child>
                    <app-collection-collapse-child [collapsed]="false">
                        <app-item-details></app-item-details>
                        <app-order-summary></app-order-summary>
                    </app-collection-collapse-child>
                </app-collection-item-collapsible>
            </app-collection>
        </div>
        <div class="col-md-3 col-lg-2 hidden-sm-down"></div>
    </div>
</ng-container>
