import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LinkRel } from '@pepconnect/enums/link-rel.enum';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';
import { Link } from '@pepconnect/models/general/link.model';
import { Locale } from '@pepconnect/models/localization/locale.model';
import { LinksService } from '@pepconnect/services/links.service';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { AppState } from '@pepconnect/state/index';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-checkout-confirmation-purchase-info',
  templateUrl: './checkout-confirmation-purchase-info.component.html',
  styleUrls: ['./checkout-confirmation-purchase-info.component.scss']
})
export class CheckoutConfirmationPurchaseInfoComponent extends BaseSmartComponent implements OnInit {
  readonly transaction$ = this.checkoutStore.transaction$;
  locale: Locale;
  constructor(private checkoutStore: CheckoutStore,
    private router: Router,
    protected store: Store<AppState>,
    private linksService: LinksService) { super(store); }

  ngOnInit(): void {
    this.$userLocale.pipe(takeUntil(this.ngUnsubscribe)).subscribe(locale => this.locale = locale);
  }

  buttonAction(link: Link) {
    switch (link.rel) {
      case LinkRel.page:
        this.router.navigateByUrl(this.linksService.transformPath(link, this.locale.locale));
        break;
      case LinkRel.printCertificate:
        this.router.navigate([this.locale.locale, 'transcript']);
        break;
      case LinkRel.purchase:
        this.router.navigate([this.locale.locale, 'store']);
        break;
      case LinkRel.activityGroupDetails:
        this.router.navigate([this.locale.locale, 'groups', link.href]);
        break;
    }
  }
}
