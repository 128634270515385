<ng-container *ngIf="{
    transaction: transaction$ | async
    } as checkoutState">
    <!-- Child components handle error$ and loading$ -->
    <div class="row margin-top-1 margin-bottom-1 checkout-container">
        <div class="col-md-3 hidden-sm-down"></div>
        <div class="col-lg-4 col-md-6 col-sm-12">
            <app-collection [collapsible]="true" class="flex-margin-t">
                <app-collection-item-collapsible class="hidden-lg-up">
                    <app-collection-collapse-child>
                        <app-collection-item-header>{{ 'ORDER_SUMMARY' | translate}}</app-collection-item-header>
                    </app-collection-collapse-child>
                    <app-collection-collapse-child [collapsed]="false">
                        <app-item-details></app-item-details>
                        <app-item-cost></app-item-cost>
                    </app-collection-collapse-child>
                </app-collection-item-collapsible>
                <app-collection-item>
                    <app-collection-item-header>{{'LABEL_PAYMENT_INFO' | translate}}</app-collection-item-header>
                </app-collection-item>
                <app-collection-item>
                    <app-group-purchase *ngIf="!checkoutState.transaction?.items[0]?.isPersonal"></app-group-purchase>
                    <app-individual-purchase *ngIf="checkoutState.transaction?.items[0]?.isPersonal"></app-individual-purchase>
                </app-collection-item>
            </app-collection>
        </div>
        <div class="col-lg-3 hidden-md-down">
            <app-collection [collapsible]="true">
                <app-collection-item-collapsible>
                    <app-collection-collapse-child>
                        <app-collection-item-header>{{ 'ORDER_SUMMARY' | translate}}</app-collection-item-header>
                    </app-collection-collapse-child>
                    <app-collection-collapse-child [collapsed]="false">
                        <app-item-details></app-item-details>
                        <app-item-cost></app-item-cost>
                    </app-collection-collapse-child>
                </app-collection-item-collapsible>
            </app-collection>
        </div>
        <div class="col-md-3 col-lg-2 hidden-sm-down"></div>
    </div>
</ng-container>
