import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@pepconnect/state/index';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';

@Component({
  selector: 'app-card-summary',
  templateUrl: './card-summary.component.html'
})
export class CardSummaryComponent extends BaseCheckoutComponent {
  readonly cardSummary$ = this.checkoutStore.cardSummary$;

  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore) {
    super(store, checkoutStore);
  }

}
