<ng-container *ngIf="{
    loading: loading$ | async,
    error: error$ | async,
    transaction: transaction$ | async
    } as checkoutState">
    <div class="row" *ngIf="checkoutState.loading">
        <app-spinner class="col-md-12"></app-spinner>
    </div>
    <div class="row col-md-12 margin-bottom-1" *ngIf="checkoutState.error">
        <app-alert indicator="error" [accent-border]="true">
            {{ checkoutState.error | translate }}
        </app-alert>
    </div>
    <div class="row margin-top-1" *ngIf="checkoutState.transaction && checkoutState.transaction.items.length && !checkoutState.error && !checkoutState.loading">
        <div class="col-md-3 hidden-sm-down"></div>
        <div class="col-lg-4 col-md-8 col-sm-12">
            <app-collection [collapsible]="true" class="flex-margin-t">
                <app-collection-item-collapsible class="hidden-lg-up">
                    <app-collection-collapse-child>
                        <app-collection-item-header>{{ 'ORDER_SUMMARY' | translate}}</app-collection-item-header>
                    </app-collection-collapse-child>
                    <app-collection-collapse-child [collapsed]="false">
                        <app-item-details></app-item-details>
                        <app-order-summary></app-order-summary>
                    </app-collection-collapse-child>
                </app-collection-item-collapsible>
                <app-collection-item>
                    <app-collection-item-header>{{ 'LABEL_PAYMENT_INFO' | translate}}</app-collection-item-header>
                </app-collection-item>
                <app-collection-item>
                    <span class="text-left" [innerText]="getCheckoutDisplayName(checkoutState.transaction)">
                    </span>
                    <br />
                    <!-- we do not save billing address for saved cards.  Only show billing address if this is a new card -->
                    <span *ngIf="!checkoutState.transaction.useExisting"
                          [innerHtml]="getCheckoutDisplayAddress(checkoutState.transaction)">
                    </span>
                    <br />
                    <app-card-summary></app-card-summary>
                </app-collection-item>
                <app-collection-item>
                    <app-form-full-width [formGroup]="termsForm" (onSubmit)="onSubmit()">
                        <app-form-group [hidden]="!checkoutState.transaction.termsAndConditionsLink">
                            <h6>{{ 'LABEL_CHECKOUT_TERMS_AND_CONDITIONS' | translate }}</h6>
                            <uimc-checkbox (checkedChange)="onTermsAgree($event)">
                                <a href="{{checkoutState.transaction.termsAndConditionsLink}}" target="_blank" class="icon-link-intern">{{ 'LABEL_CHECKOUT_TERMS_AND_CONDITIONS_LINK'  | translate }}</a>
                            </uimc-checkbox>
                            <app-validation-message [control]="f.termsAgree" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD" type="none-with-invalid"></app-validation-message>
                        </app-form-group>
                        <app-form-buttons>
                            <button id="finalize-cancel" class="button button--secondary" type="button" (click)="onCancel()">{{'BUTTON_CANCELPURCHASE' | translate}}</button>
                            <button id="finalize-continue" class="button button--primary" type="submit" [disabled]="checkoutState.transaction.termsAndConditionsLink && !f.termsAgree?.value">{{ 'LABEL_PLACE_ORDER'| translate}}</button>
                        </app-form-buttons>
                    </app-form-full-width>
                </app-collection-item>
            </app-collection>
        </div>
        <div class="col-lg-3 hidden-md-down">
            <app-collection [collapsible]="true">
                <app-collection-item-collapsible>
                    <app-collection-collapse-child>
                        <app-collection-item-header>{{ 'ORDER_SUMMARY' | translate}}</app-collection-item-header>
                    </app-collection-collapse-child>
                    <app-collection-collapse-child [collapsed]="false">
                        <app-item-details></app-item-details>
                        <app-order-summary></app-order-summary>
                    </app-collection-collapse-child>
                </app-collection-item-collapsible>
            </app-collection>
        </div>
        <div class="col-md-3 col-lg-2 hidden-sm-down"></div>
    </div>
</ng-container>

