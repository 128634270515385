<ng-container *ngIf="{ transaction: transaction$ | async, user: $user | async } as state">
    <h3 [innerHTML]="'TEXT_PURCHASE_THANKYOU' | translate"></h3>
    <p class="font-size-large" [innerHTML]="('TEXT_ORDER_PLACED' | translate).replace('@@ORDERNUM@@', state.transaction.orderId)"></p>
    <p [innerHTML]="('TEXT_ORDER_EMAIL' | translate).replace('@@EMAIL@@', state.transaction.billingAddress?.email ?? state.user.email)"></p>
    <p [innerHTML]="state.transaction.message | translate"></p>
    <p>
        {{ 'TEXT_ORDER_PURCHASE_HISTORY_NOTE' | translate }}
        <a class="purchase-link" [routerLink]="['/', locale.locale, 'profile' , 'purchases' ]">{{ 'PROFILE_MENU_LINK4' | translate }}</a>
    </p>
    <p>{{ 'TEXT_ORDER_HAPPY' | translate }}</p>
    <app-action-buttons alignment="end">
        <button type="button" class="button" *ngFor="let action of state.transaction.items[0].links | buttonSort" (click)="buttonAction(action)"
                [ngClass]="{'button--primary': action.linkButtonType === 'primary', 'button--secondary': action.linkButtonType === 'secondary' }">
            {{ action.text | translate }}
        </button>
    </app-action-buttons>
</ng-container>
