import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';

@Component({
  selector: 'app-checkout-confirmation-payment-info',
  templateUrl: './checkout-confirmation-payment-info.component.html',
  styleUrls: ['./checkout-confirmation-payment-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutConfirmationPaymentInfoComponent {
  readonly transaction$ = this.checkoutStore.transaction$;
  constructor(private checkoutStore: CheckoutStore) { }
}
