<ng-container *ngIf="{
    loading: loading$ | async,
    error: error$ | async
    } as checkoutState">
    <div class="row margin-top-1 margin-bottom-1" *ngIf="!checkoutState.error && !checkoutState.loading">
        <div class="col-md-3 hidden-sm-down"></div>
        <div class="col-lg-4 col-md-6 col-sm-12">
            <app-collection class="flex-margin-t">
                <app-collection-item class="hidden-lg-up">
                    <app-collection-item-header>{{ 'ORDER_SUMMARY' | translate}}</app-collection-item-header>
                </app-collection-item>
                <app-collection-item class="hidden-lg-up">
                    <app-item-details></app-item-details>
                </app-collection-item>
                <app-collection-item>
                    <app-collection-item-header>{{ 'LABEL_PAYMENT_INFO' | translate}}</app-collection-item-header>
                </app-collection-item>
                <app-collection-item>
                    <app-form-full-width (onSubmit)="onSubmit()" [formGroup]="facilitySelectForm">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="font-weight-bold">{{ 'REGISTRATION_PAYMENT_TYPE' | translate }}</label>: {{ facilityPaymentType.name | translate }}
                            </div>
                            <div class="col-md-12" *ngIf="facilityPaymentType.requireTCVNum">
                                <app-form-group>
                                    <app-textfield-group [skip-invalid]="false">
                                        <label class="textfield__label" for="facility-select-tcvnum">{{ 'REGISTRATION_VOUCHER' | translate }}</label>
                                        <input type="text" class="textfield__input" id="facility-select-tcvnum" name="tcv-number" formControlName="tcvNumber">
                                        <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true"></app-form-hint>
                                        <app-validation-message [control]="f.tcvNumber" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD"></app-validation-message>
                                    </app-textfield-group>
                                </app-form-group>
                            </div>
                            <div class="col-md-12" *ngIf="facilityAccountOptions?.length > 1">
                                <app-form-group>
                                    <uimc-filtered-select-v2 id="facility-select-dropdown" formControlName="facilityAccount"
                                        [labelSearch]="'PLACEHOLDER_TYPE_FILTER' | translate"
                                        [label]="'FACILITY' | translate"
                                        [filterThreshold]="2"
                                        [selectedItem]="selectedOption" 
                                        (selectedItemChange)="onSelectedFacility($event)">
                                        <option *ngFor="let option of facilityAccountOptions" [value]="option.value">
                                        {{option.name}}</option>
                                            <app-form-hint type="selectbox" hint="{{ 'TEXT_SELECT_ONE' | translate }}" [hideOnSubmitted]="false"></app-form-hint>
                                            <app-validation-message [control]="f.facilityAccount" [showOnSubmitted]="true"></app-validation-message>
                                    </uimc-filtered-select-v2>
                                </app-form-group>
                            </div>
                            <div class="col-md-12" *ngIf="facilityPaymentType.requireTCVNum" [ngClass]="{'no-facility-selected': !noFacilitySelected}">
                                <app-form-group>
                                    <app-textfield-group [ngClass]="{'disable-click': !noFacilitySelected}">
                                        <label class="textfield__label" for="facility-select-accountid">{{ 'ACCOUNT_ID' | translate }}</label>
                                        <input type="text" class="textfield__input" id="facility-select-accountid" name="account-id" formControlName="manualAccountId">
                                        <app-form-hint [hint]="'LABEL_OPTIONAL'" [hideOnSubmitted]="true"></app-form-hint>
                                    </app-textfield-group>
                                </app-form-group>
                            </div>
                            <div class="col-md-12" *ngIf="!facilityPaymentType.requireTCVNum" [ngClass]="{'no-facility-selected': !noFacilitySelected}">
                                <app-form-group>
                                    <app-textfield-group [ngClass]="{'disable-click': !noFacilitySelected}">
                                        <label class="textfield__label" for="facility-select-accountid">{{ 'ACCOUNT_ID' | translate }}</label>
                                        <input type="text" class="textfield__input" id="facility-select-accountid" name="account-id" formControlName="manualAccountId">
                                        <app-form-hint [hint]="'PLACEHOLDER_REQUIRED'" [hideOnSubmitted]="true"></app-form-hint>
                                        <app-validation-message [control]="f.manualAccountId" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD"></app-validation-message>
                                    </app-textfield-group>
                                </app-form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <app-form-buttons>
                                    <button id="facility-select-cancel" class="button button--secondary" type="button" *ngIf="!checkoutState.loading" (click)="onCancel()">{{'BUTTON_CANCELPURCHASE' | translate}}</button>
                                    <button id="facility-select-continue" class="button button--primary" type="submit" *ngIf="!checkoutState.loading">{{ 'BUTTON_CONTINUE'| translate}}</button>
                                    <app-spinner *ngIf="checkoutState.loading"></app-spinner>
                                </app-form-buttons>
                            </div>
                        </div>
                    </app-form-full-width>
                </app-collection-item>
            </app-collection>
        </div>
        <div class="col-lg-3 hidden-md-down">
            <app-collection>
                <app-collection-item>
                    <app-collection-item-header>{{ 'ORDER_SUMMARY' | translate}}</app-collection-item-header>
                </app-collection-item>
                <app-collection-item>
                    <app-item-details></app-item-details>
                </app-collection-item>
            </app-collection>
        </div>
        <div class="col-md-3 col-lg-2 hidden-sm-down"></div>
    </div>
    <div *ngIf="checkoutState.error" id="error-panel">
        <app-alert indicator="error" [accent-border]="true">
            {{ checkoutState.error | translate }}
        </app-alert>
    </div>
</ng-container>
