import { Component } from '@angular/core';
import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';
import { AppState } from '@pepconnect/state/index';
import { CheckoutStepType } from '@pepconnect/features/checkout/enums/checkout-step-type.enum';

@Component({
  selector: 'app-select-payment-type',
  templateUrl: './select-payment-type.component.html'
})
export class SelectPaymentTypeComponent extends BaseCheckoutComponent {
  readonly FACILITY = 'facility';
  readonly CARD = 'card';
  paymentOption: string = this.CARD;

  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore
  ) {
    super(store, checkoutStore);
  }

  onContinue() {
    if (this.paymentOption === this.CARD) {
      this.checkoutStore.transaction$.pipe(take(1)).subscribe({
        next: (tx) => this.checkoutStore.setNextPaymentStep(tx)
      });
    }
    if (this.paymentOption === this.FACILITY)
      this.checkoutStore.setSelectedStep(CheckoutStepType.FacilitySelect);
  }
}
