import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';
import { AppState } from '@pepconnect/state/index';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { CheckoutStepType } from '@pepconnect/features/checkout/enums/checkout-step-type.enum';
import { CardStepType } from '@pepconnect/features/checkout/enums/card-step-type.enum';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html'
})
export class OrderSummaryComponent extends BaseCheckoutComponent {
  readonly transaction$ = this.checkoutStore.transaction$;
  readonly selectedStep$ = this.checkoutStore.selectedStep$;
  readonly selectedCardStep$ = this.checkoutStore.selectedCardStep$;

  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore) {
    super(store, checkoutStore);
  }

  get CheckoutStepType() { return CheckoutStepType; }  // so template can access
  get CardStepType() { return CardStepType; }  // so template can access

}

