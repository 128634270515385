    <app-block-centered>
        <div *ngIf="errorMessage" id="error-panel">
            <app-alert indicator="error"
                       [accent-border]="true">
                {{ errorMessage | translate }}
            </app-alert>
        </div>
    </app-block-centered>
    <div class="row margin-top-1 margin-bottom-1">
        <div class="col-md-12">
            <app-form-centered (onSubmit)="onSubmit()" [formGroup]="guestRegistrationForm" class="margin-top-1">
                <p *ngIf="showTabsNavigationMessage">{{ 'REGISTER_USE_TABS_TO_NAVIGATE' | translate }}</p>
                <app-form-group>
                    <app-textfield-group [skip-invalid]="false">
                        <label class="textfield__label" for="guest-registration-first-name">{{ 'FIRST_NAME' | translate }}</label>
                        <input type="text" class="textfield__input" id="guest-registration-first-name" name="first" formControlName="firstname" autocomplete="given-name">
                        <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true"></app-form-hint>
                        <app-validation-message [control]="f.firstname" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD_FIRST"></app-validation-message>
                    </app-textfield-group>
                </app-form-group>
                <app-form-group>
                    <app-textfield-group [skip-invalid]="false">
                        <label class="textfield__label" for="guest-registration-last-name">{{ 'LAST_NAME' | translate }}</label>
                        <input type="text" class="textfield__input" id="guest-registration-last-name" name="last" formControlName="lastname" autocomplete="family-name">
                        <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true" [control]="f.lastname"></app-form-hint>
                        <app-validation-message [control]="f.lastname" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD_LAST"></app-validation-message>
                    </app-textfield-group>
                </app-form-group>
                <app-form-group>
                    <app-textfield-group [skip-invalid]="false">
                        <label class="textfield__label" for="guest-registration-email">{{ 'EMAIL' | translate }}</label>
                        <input type="email" class="textfield__input" id="guest-registration-email" name="email" formControlName="email" autocomplete="email" email>
                        <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true" [control]="f.email"></app-form-hint>
                        <app-validation-message [control]="f.email" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD_EMAIL"></app-validation-message>
                        <app-validation-message [control]="f.email" error="email" message="FIELD_CHECK_EMAIL"></app-validation-message>
                        <div *ngIf="f.email.errors?.['siemensEmail']" class="textfield__hint">
                            <p>
                                {{ 'FIELD_SIEMENS_EMAIL' | translate }}
                                <a [href]="tcmanLink" class="button button--primary button--small display-block">{{'SIEMENS_TCMAN_LINK' | translate }}</a>
                                <br />
                            </p>
                        </div>
                    </app-textfield-group>
                </app-form-group>
                <app-form-group>
                    <h6>{{ 'AGREEMENT_BLURB_HEADER' | translate }}</h6>
                    <uimc-checkbox (checkedChange)="onTermsAgree($event)">
                        <a href="http://www.healthcare.siemens.com/terms-of-use" target="_blank" class="icon-link-intern">{{ 'TERMS_OF_USE'  | translate }}</a>
                    </uimc-checkbox>
                    <app-validation-message [control]="f.termsAgree" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD" type="none-with-invalid"></app-validation-message>
                    <br />
                    <uimc-checkbox (checkedChange)="onPrivacyAgree($event)">
                        <a [routerLink]="['/', userLocale.locale, 'privacy']" target="_blank" class="icon-link-intern">{{ 'PRIVACY_POLICY'  | translate }}</a>
                    </uimc-checkbox>
                    <app-validation-message [control]="f.privacyAgree" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD" type="none-with-invalid"></app-validation-message>
                </app-form-group>
                <app-form-buttons>
                    <button id="guest-registration-cancel" class="button button--secondary" type="button" (click)="onCancel()">{{'BUTTON_CANCELPURCHASE' | translate}}</button>
                    <button id="guest-registration-continue" class="button button--primary" type="submit" [disabled]="loading">{{ 'BUTTON_CONTINUE'| translate}}</button>
                </app-form-buttons>
            </app-form-centered>
        </div>
    </div>
