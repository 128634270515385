import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';
import { AppState } from '@pepconnect/state/index';

@Component({
  selector: 'app-checkout-confirmation-billing-info',
  templateUrl: './checkout-confirmation-billing-info.component.html',
  styleUrls: ['./checkout-confirmation-billing-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutConfirmationBillingInfoComponent extends BaseCheckoutComponent {
  readonly transaction$ = this.checkoutStore.transaction$;
  constructor(protected checkoutStore: CheckoutStore, protected store: Store<AppState>) { super(store, checkoutStore) }
}
