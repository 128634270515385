<ng-container *ngIf="transaction$ | async as transaction">
    <app-collection-item *ngIf="transaction.paymentInfo.cardType">
        <h6>{{'LABEL_ITEM_SUB_TOTAL' | translate}}</h6>
        <h6>{{ 'LABEL_TAX' | translate}}</h6>
        <em class="font-size-xsmall color-black-60">&ast;{{ 'CHECKOUT_TAX_MESSAGE' | translate }}</em>
        <app-collection-item-additional-content>
            <h6 class="margin-top-0">{{ transaction.formattedItemTotal }}</h6>
            <h6 class="margin-top-1 margin-bottom-1">{{ transaction.formattedTax }}</h6>
        </app-collection-item-additional-content>
    </app-collection-item>
    <app-collection-item>
        <h6>{{ 'LABEL_ORDER_TOTAL' | translate}}</h6>
        <em *ngIf="transaction.items[0]?.itemType != orderItemType.EventOfferingRegistration" class="font-size-xsmall color-black-60">&ast;{{ 'CHECKOUT_SALES_MESSAGE' | translate }}</em>
        <app-collection-item-additional-content>
            <h6 class="margin-top-0" *ngIf="transaction.paymentInfo.cardType">{{ transaction.formattedTotal }}</h6>
            <h6 class="margin-top-0" *ngIf="transaction.pointsApplied > 0">{{ transaction.pointsApplied }}
                <span [ngPlural]="transaction.pointsApplied">
                <ng-template ngPluralCase="=1">
                    {{'LABEL_POINT' | translate}}
                </ng-template>
                <ng-template ngPluralCase="other">
                    {{'LABEL_POINTS' | translate}}
                </ng-template>
            </span></h6>
        </app-collection-item-additional-content>
    </app-collection-item>
</ng-container>
