import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@pepconnect/state';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';
import { BaseCheckoutComponent } from '@pepconnect/features/checkout/base/base-checkout.component';
import { CheckoutTransaction } from '@pepconnect/features/checkout/models/checkout-transaction.model';
import { CheckoutBillingAddress } from '@pepconnect/features/checkout/models/checkout-billing-address.model';
import { StateOrProvince } from '@pepconnect/models/intl/state.model';

type BillingAddressForm = Required<{
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  address1: FormControl<string>;
  address2: FormControl<string>;
  city: FormControl<string>;
  state: FormControl<string>;
  zip: FormControl<string>;
}>


@Component({
  selector: 'app-billing-address',
  templateUrl: './billing-address.component.html'
})
export class BillingAddressComponent extends BaseCheckoutComponent implements OnInit {
  readonly transaction$ = this.checkoutStore.transaction$;
  readonly VALIDATOR_NAME_MAX_LENGTH = 60;
  readonly VALIDATOR_ADDRESS_MAX_LENGTH = 60;
  readonly VALIDATOR_CITY_MAX_LENGTH = 50;
  readonly VALIDATOR_ZIP_MAX_LENGTH = 10;
  
  billingAddressForm: FormGroup<BillingAddressForm>;
  transaction: CheckoutTransaction;
  selectedState: StateOrProvince;
  
  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore,
    private fb: FormBuilder) {
    super(store, checkoutStore);
  }

  ngOnInit(): void {
    this.setupForm();
  }

  get f() {
    return this.billingAddressForm?.controls;
  }

  setupForm() {
    this.transaction$.pipe(take(1)).subscribe((tx) => {
      // save the initial transaction for later
      this.transaction = tx;
      this.billingAddressForm = this.fb.nonNullable.group({
        firstName: this.fb.control(tx.billingAddress.firstName, [Validators.required, Validators.maxLength(this.VALIDATOR_NAME_MAX_LENGTH)]),
        lastName: this.fb.control(tx.billingAddress.lastName, [Validators.required, Validators.maxLength(this.VALIDATOR_NAME_MAX_LENGTH)]),
        address1: this.fb.control(tx.billingAddress.address1, [Validators.required, Validators.maxLength(this.VALIDATOR_ADDRESS_MAX_LENGTH)]),
        address2: this.fb.control(tx.billingAddress.address2, [Validators.maxLength(this.VALIDATOR_ADDRESS_MAX_LENGTH)]),
        city: this.fb.control(tx.billingAddress.city, [Validators.required, Validators.maxLength(this.VALIDATOR_CITY_MAX_LENGTH)]),
        state: this.fb.control(''),
        zip: this.fb.control(tx.billingAddress.zip, [Validators.required, Validators.maxLength(this.VALIDATOR_ZIP_MAX_LENGTH)]),
      })
    })
  }

  onSelectedState($event: StateOrProvince) {
    this.selectedState = $event;
  }

  onSubmit() {
    if (!this.billingAddressForm || this.billingAddressForm.invalid) {
      return;
    }
    const value = this.billingAddressForm.value;
    let payload: CheckoutBillingAddress = 
    {
      ...this.transaction.billingAddress,
      firstName: value.firstName,
      lastName: value.lastName,
      address1: value.address1,
      address2: value.address2,
      city: value.city,
      state: {
        ...this.transaction.billingAddress.state,
        abbreviation: this.selectedState?.abbreviation,
        id: this.selectedState?.id,
        name: this.selectedState?.name,
      },
      zip: value.zip,
    };

    this.checkoutStore.createTransaction(payload);
  }
}

