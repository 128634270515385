<ng-container *ngIf="transaction$ | async as transaction">
    <app-collection-item *ngIf="transaction.paymentInfo.cardType">
        <h6 class="margin-bottom-single">{{ 'LABEL_BILLING_ADDRESS' | translate }}</h6>
        <address>
            {{ getCheckoutDisplayName(transaction) }}<br>
            <span [innerHTML]="getCheckoutDisplayAddress(transaction)"></span><br>
            <br>
        </address>
    </app-collection-item>
</ng-container>
