<ng-container *ngIf="{
    loading: loading$ | async,
    error: error$ | async
    } as checkoutState">
    <div class="row margin-top-1 margin-bottom-1" *ngIf="!checkoutState.error && !checkoutState.loading">
        <div class="col-md-3 hidden-sm-down"></div>
        <div class="col-lg-4 col-md-6 col-sm-12">
            <app-collection class="flex-margin-t">
                <app-collection-item class="hidden-lg-up">
                    <app-collection-item-header>{{ 'ORDER_SUMMARY' | translate}}</app-collection-item-header>
                </app-collection-item>
                <app-collection-item class="hidden-lg-up">
                    <app-item-details></app-item-details>
                </app-collection-item>
                <app-collection-item>
                    <app-collection-item-header>{{ 'LABEL_PAYMENT_INFO' | translate}}</app-collection-item-header>
                </app-collection-item>

                <app-collection-item>
                    <app-form-full-width (onSubmit)="onSubmit()" [formGroup]="facilityRegistrationForm">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="firstName" class="font-weight-bold">{{ 'LABEL_NAME' | translate }}</label>
                                    {{ user.firstname + ' ' + user.lastname }}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{ 'EMAIL_ADDRESS' | translate }}</label>
                                    {{ user.email }}
                                </div>
                            </div>
                        </div>

                        <app-collection-item [fullBordered]="true" *ngIf="transaction?.facility?.facilityAccountId">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="font-weight-bold">{{ 'FACILITY' | translate }}</label>
                                        {{ transaction.facility.name }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="font-weight-bold">{{ 'ACCOUNT_ID' | translate }}</label>
                                        {{ transaction.facility.accountId }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="font-weight-bold">{{ 'LABEL_FACILITY_ADDRESS' | translate }}</label>
                                        {{ transaction?.facility?.addresses[0]?.street1 }}
                                        {{ transaction?.facility?.addresses[0]?.city }}, {{ transaction?.facility?.addresses[0]?.region }} {{ transaction?.facility?.addresses[0]?.postcode }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 padding-top-1">
                                    <div class="text-center">
                                        <p>{{ 'TEXT_NOT_YOUR_FACILITY' | translate }}</p>
                                        <p><button type="button" class="button button--secondary" (click)="initializeFacility()">{{'TEXT_ENTER_FACILITY_INFO' | translate }}</button></p>
                                    </div>
                                </div>
                            </div>
                        </app-collection-item>

                        <div *ngIf="!transaction?.facility?.facilityAccountId">
                            <div class="row">
                                <div class="col-md-12">
                                    <app-form-group>
                                        <app-textfield-group [skip-invalid]="false">
                                            <label class="textfield__label" for="facility-select-tcvnum">{{ 'FACILITY' | translate }}</label>
                                            <input type="text" class="textfield__input" id="facility-select-tcvnum" name="facility-name" formControlName="facilityName">
                                            <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true"></app-form-hint>
                                            <app-validation-message [control]="f.facilityName" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD"></app-validation-message>
                                        </app-textfield-group>
                                    </app-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-form-group>
                                        <app-textfield-group [skip-invalid]="false">
                                            <label class="textfield__label" for="facility-account-id">{{ 'ACCOUNT_ID' | translate }}</label>
                                            <input type="text" class="textfield__input" id="facility-account-id" name="facility-account-id" formControlName="accountId">
                                            <app-form-hint hint="{{ requireTCVNum ? 'LABEL_OPTIONAL' : 'PLACEHOLDER_REQUIRED' }}" [hideOnSubmitted]="true"></app-form-hint>
                                            <app-validation-message [control]="f.accountId" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD"></app-validation-message>
                                        </app-textfield-group>
                                    </app-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-form-group>
                                        <app-textfield-group [skip-invalid]="false">
                                            <label class="textfield__label" for="facility-address">{{ 'ADDRESS' | translate }}</label>
                                            <input type="text" class="textfield__input" id="facility-address" name="facility-address" formControlName="facilityAddress">
                                            <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true"></app-form-hint>
                                            <app-validation-message [control]="f.facilityAddress" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD"></app-validation-message>
                                        </app-textfield-group>
                                    </app-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-form-group>
                                        <app-textfield-group [skip-invalid]="false">
                                            <label class="textfield__label" for="facility-city">{{ 'CITY' | translate }}</label>
                                            <input type="text" class="textfield__input" id="facility-city" name="facility-city" formControlName="facilityCity">
                                            <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true"></app-form-hint>
                                            <app-validation-message [control]="f.facilityCity" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD"></app-validation-message>
                                        </app-textfield-group>
                                    </app-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6" *ngIf="states?.length > 0">
                                    <app-form-group>
                                        <app-textfield-group [skip-invalid]="false">
                                            <label class="textfield__label" for="facility-city-province">{{ 'REPORT_CITYPROVINCE_LABEL' | translate }}</label>
                                            <input type="text" class="textfield__input" id="facility-city-province" name="facility-city-province" formControlName="facilityCityProvince">
                                            <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true"></app-form-hint>
                                            <app-validation-message [control]="f.facilityCityProvince" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD"></app-validation-message>
                                        </app-textfield-group>
                                    </app-form-group>
                                </div>
                                <div class="col-md-6">
                                    <app-form-group>
                                        <app-textfield-group [skip-invalid]="false">
                                            <label class="textfield__label" for="facility-zip">{{ 'ZIP' | translate }}</label>
                                            <input type="text" class="textfield__input" id="facility-zip" name="facility-zip" formControlName="facilityZip">
                                            <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true"></app-form-hint>
                                            <app-validation-message [control]="f.facilityZip" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD"></app-validation-message>
                                        </app-textfield-group>
                                    </app-form-group>
                                </div>
                            </div>
                        </div>

                        <div [ngClass]="{'margin-top-1': transaction?.facility?.facilityAccountId}">
                            <div class="row">
                                <div class="col-md-12">
                                    <app-form-group>
                                        <app-textfield-group>
                                            <label class="textfield__label" for="facility-phone">{{ 'LABEL_FACILITY_PHONE' | translate }}</label>
                                            <input type="text" class="textfield__input" id="facility-phone" name="facility-phone" formControlName="facilityPhone">
                                            <app-form-hint hint="{{ 'LABEL_OPTIONAL' }}" [hideOnSubmitted]="true"></app-form-hint>
                                        </app-textfield-group>
                                    </app-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-form-group>
                                        <app-textfield-group>
                                            <app-form-help-button (clicked)="openSidModal()"></app-form-help-button>
                                            <label class="textfield__label" for="functional-loc-num">{{ 'LABEL_FUNCTIONALLOCATIONNUMBER' | translate }}</label>
                                            <input maxlength="255" type="text" class="textfield__input" id="facility-phone" name="functional-loc-num" formControlName="facilityFuncLocationNumber">
                                            <app-form-hint hint="{{ 'LABEL_OPTIONAL' }}" [hideOnSubmitted]="true"></app-form-hint>
                                        </app-textfield-group>
                                    </app-form-group>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <app-form-buttons>
                                    <button id="facility-register-cancel" class="button button--secondary" type="button" *ngIf="!checkoutState.loading" (click)="onCancel()">{{'BUTTON_CANCELPURCHASE' | translate}}</button>
                                    <button id="facility-register-continue" class="button button--primary" type="submit" *ngIf="!checkoutState.loading">{{ 'BUTTON_CONTINUE'| translate}}</button>
                                    <app-spinner *ngIf="checkoutState.loading"></app-spinner>
                                </app-form-buttons>
                            </div>
                        </div>
                    </app-form-full-width>
                </app-collection-item>
            </app-collection>
        </div>
        <div class="col-lg-3 hidden-md-down">
            <app-collection>
                <app-collection-item>
                    <app-collection-item-header>{{ 'ORDER_SUMMARY' | translate}}</app-collection-item-header>
                </app-collection-item>
                <app-collection-item>
                    <app-item-details></app-item-details>
                </app-collection-item>
            </app-collection>
        </div>
        <div class="col-md-3 col-lg-2 hidden-sm-down"></div>
    </div>
    <div *ngIf="checkoutState.error" id="error-panel">
        <app-alert indicator="error" [accent-border]="true">
            {{ checkoutState.error | translate }}
        </app-alert>
    </div>
</ng-container>
