<ng-container *ngIf="{
    loading: loading$ | async,
    error: error$ | async
    } as checkoutState">
    <div class="row" *ngIf="checkoutState.loading">
        <app-spinner class="col-md-12"></app-spinner>
    </div>
    <div class="row" *ngIf="checkoutState.error">
        <uimc-alert title="" indicator="error" class="col-md-12 margin-bottom-1">
            {{ checkoutState.error | translate }}
        </uimc-alert>
    </div>
    <div class="row margin-top-1 margin-bottom-2" *ngIf="!checkoutState.error && !checkoutState.loading">
        <div class="col-md-3 hidden-sm-down"></div>
        <div class="col-lg-4 col-md-6 col-sm-12">
            <app-collection class="flex-margin-t">
                <app-collection-item class="hidden-lg-up">
                    <app-collection-item-header>{{ 'ORDER_SUMMARY' | translate}}</app-collection-item-header>
                </app-collection-item>
                <app-collection-item class="hidden-lg-up">
                    <app-item-details></app-item-details>
                    <app-item-cost></app-item-cost>
                </app-collection-item>
                <app-collection-item>
                    <app-collection-item-header>{{ 'TEXT_CHOOSE_PAYMENT' | translate}}</app-collection-item-header>
                </app-collection-item>
                <app-collection-item>
                    <div class="form-group m-auto w-50 text-left">
                        <input type="radio" id="select-payment-method-card" [value]="CARD" [(ngModel)]="paymentOption">
                        <label for="select-payment-method-card" class="font-weight-bold">{{'TEXT_CARD_OR_POINTS' | translate }}</label><br>
                        <input type="radio" id="select-payment-method-facility" [value]="FACILITY" [(ngModel)]="paymentOption">
                        <label for="select-payment-method-facility" class="font-weight-bold">{{ 'TEXT_APPLY_FACILITY_CREDITS' | translate }}</label><br>
                    </div>
                </app-collection-item>
                <app-collection-item>
                    <app-form-buttons id="select-payment-method-actions" class="margin-top-1">
                        <button id="select-payment-method-cancel" class="button button--secondary" (click)="onCancel()">{{'BUTTON_CANCEL' | translate}}</button>
                        <button id="select-payment-method-continue" class="button button--primary" (click)="onContinue()">{{ 'BUTTON_CONTINUE'| translate}}</button>
                        <br/>
                    </app-form-buttons>
                </app-collection-item>
            </app-collection>
        </div>
        <div class="col-lg-3 hidden-md-down">
            <app-collection>
                <app-collection-item>
                    <app-collection-item-header>{{ 'ORDER_SUMMARY' | translate}}</app-collection-item-header>
                </app-collection-item>
                <app-collection-item>
                    <app-item-details></app-item-details>
                    <app-item-cost></app-item-cost>
                </app-collection-item>
            </app-collection>
        </div>
        <div class="col-md-3 col-lg-2 hidden-sm-down"></div>
    </div>
</ng-container>
