import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CheckoutStore } from '@pepconnect/features/checkout/checkout.store';

@Component({
  selector: 'app-checkout-confirmation-points-info',
  templateUrl: './checkout-confirmation-points-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutConfirmationPointsInfoComponent {
  readonly transaction$ = this.checkoutStore.transaction$;
  readonly user$ = this.checkoutStore.checkoutUser$;

  constructor(private checkoutStore: CheckoutStore) { }
}
