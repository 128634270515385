import { FacilityAccountAddress } from "@pepconnect/models/facility-account/facility-account-address.model";
import { FacilityAccountPhoneNumber } from "@pepconnect/models/facility-account/facility-account-phone-number.model";
import { FacilityAccountSystemIdentifier } from "@pepconnect/models/facility-account/facility-account-system-identifier.model";

export interface FacilityAccount {
  facilityAccountId: string | null;
  admAccountId: string;
  name: string;
  accountId: string;
  accountLine2: string;
  accountLine3: string;
  createdById: number;
  createdByName: string;
  createdDate: string;
  modifiedById: number;
  modifiedByName: string;
  modifiedDate: string;
  addresses: FacilityAccountAddress[];
  systemIdentifiers: FacilityAccountSystemIdentifier[];
  phoneNumbers: FacilityAccountPhoneNumber[];
  active: boolean;
}

export function defaultFacilityAccountFactory(): FacilityAccount {
  return {
    facilityAccountId: null,
    admAccountId: '',
    name: '',
    accountId: '',
    accountLine2: '',
    accountLine3: '',
    createdById: 0,
    createdByName: '',
    createdDate: '',
    modifiedById: 0,
    modifiedByName: '',
    modifiedDate: '',
    addresses: [],
    systemIdentifiers: [],
    phoneNumbers: [],
    active: false,
  } as FacilityAccount;
}
