import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { BaseSmartComponent } from "@pepconnect/shared/base/base-smart-component/base-smart.component";
import { AppState } from "@pepconnect/state/index";
import { TeamHelpersService } from "@pepconnect/features/teams/services/team-helpers.service";
import { setCancelCheckout } from "@pepconnect/state/events/events.actions";
import { CheckoutStore } from "@pepconnect/features/checkout/checkout.store";
import { CheckoutStepType } from "@pepconnect/features/checkout/enums/checkout-step-type.enum";
import { CardStep } from "@pepconnect/features/checkout/models/card-step.model";
import { CardStepType } from "@pepconnect/features/checkout/enums/card-step-type.enum";
import { CheckoutTransaction } from "@pepconnect/features/checkout/models/checkout-transaction.model";

@Component({ template: '' })
export class BaseCheckoutComponent extends BaseSmartComponent {
  readonly error$ = this.checkoutStore.error$;
  readonly loading$ = this.checkoutStore.loading$;

  teamHelpers = new TeamHelpersService();

  constructor(
    protected store: Store<AppState>,
    protected checkoutStore: CheckoutStore,
  ) {
    super(store);
  }

  get TeamHelpers() { return this.teamHelpers; }

  onCancel() {
    this.store.dispatch(setCancelCheckout({ cancelCheckout: true, redirectHome: false }));
  }

  goToCard() {
    this.checkoutStore.setTransactionUseExisting(false);
    this.checkoutStore.setSelectedStep(CheckoutStepType.Card);
  }

  /**
   *
   * Utility functions for telling if step is completed
   *
   * */
  isStepStarted(steps: CardStep[], step: CardStepType): boolean {
    return steps.some(s => s.step === step && s.started);
  }

  isStepCompleted(steps: CardStep[], step: CardStepType): boolean {
    return steps.some(s => s.step === step && s.completed);
  }

  isStepSelected(steps: CardStep[], step: CardStepType): boolean {
    return steps.some(s => s.step === step && s.selected);
  }

  /**
   * Utility function to display billing address name vs saved card name
   * */
  getCheckoutDisplayName(tx: CheckoutTransaction) {
    if (tx.useExisting) {
      return `${tx.subscription?.firstName} ${tx.subscription?.lastName}`;
    }
    else {
      return `${tx.billingAddress?.firstName} ${tx.billingAddress?.lastName}`;
    }
  }

  /**
   * Utility function to display billing address formatted
   *
   * Note:  this is US format.  In future should make this more international
   * */
  getCheckoutDisplayAddress(tx: CheckoutTransaction) {
    const billingAddress2 = tx.billingAddress?.address2 ? `, ${tx.billingAddress?.address2}` : ''
    return `${tx.billingAddress?.address1}${billingAddress2}<br/>${tx.billingAddress?.city}, ${tx.billingAddress?.state?.abbreviation} ${tx.billingAddress?.zip}`;
  }

}
