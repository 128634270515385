<ng-container *ngIf="transaction$ | async as transaction">
    <app-collection-item *ngIf="transaction.pointsApplied > 0">
        <h6 class="margin-bottom-single">{{ 'LABEL_CREDIT_PAYMENT_TYPE_INFO' | translate }}</h6>
            <div>
                {{ 'LABEL_GROUP' | translate }}&colon;
                <span class="font-weight-bold">&nbsp; {{ transaction.items[0].team.name }}</span>
            </div>
            <div>
                {{ 'LABEL_PURCHASER' | translate }}&colon;
                <span class="font-weight-bold" *ngIf="user$ | async as user"> &nbsp; {{user.email}}</span>
            </div>
            <div>
                {{ 'LABEL_POINTS_REMAINING' | translate }}&colon;
                <span class="font-weight-bold">&nbsp;{{ transaction.items[0].team.creditCount - transaction.pointsApplied | localeNumber }}</span>
            </div>
    </app-collection-item>
</ng-container>
